import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function SnackBar({open, setOpen, message, severity}) {

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        console.log(message)
        setOpen(false);
    };
    

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Top-center position
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        key={"snackbar"}
        severity={severity}
        action={action}
      />
    </div>
  );
}
