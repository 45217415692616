import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Snackbar,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import axios from "axios";
import {
  MailOutline,
  Visibility,
  VisibilityOff,
  Phone,
} from "@mui/icons-material";
import logosvg1 from "../assets/image2.png";
import logo from "../assets/logo.png";
import { BACKEND_PROD_URL } from "../config";
import { getUser } from "../apis/userApis";

const Login = () => {
  const [tab, setTab] = useState(0);
  const location = useLocation();
  const [alertText, setAlertText] = useState("");
  const [onboardAlertText, setOnBoardAlertText] = useState("");
  const [reqData, setReqData] = useState({});
  const [resAlert, setResAlert] = useState("");
  const { isAuthenticated, setCurrentUser, setIsAuthenticated } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [loginId, setLoginId] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [gstno, setGstno] = useState("");
  const [address, setAddress] = useState("");
  const [company, setCompany] = useState("");
  const [passwordTwo, setPasswordTwo] = useState("");
  const [verifiedUser, setVerifiedUser] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (location.pathname === "/login") {
      setTab(0);
    } else if (location.pathname === "/register") {
      setTab(1);
    }
  }, [location.pathname]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }

    if (Object.keys(reqData).length === 2 && alertText === "") {
      const config = {
        headers: { "Content-Type": "application/json" },
      };

      async function loginUser(config) {
        try {
          const res = await axios.post(
            `${BACKEND_PROD_URL}/api/user/login`,
            reqData,
            config
          );
          if (res.status === 200 && res.data != null) {
            const { message, notifications, response } = res.data;
            const userData = await getUser(response);
            if (userData.status === 200) {
              setIsAuthenticated(true);
              setCurrentUser(userData.data);
              localStorage.setItem("token", response);
              navigate("/home");
            } else {
              setResAlert("Please contact admin!");
            }
            if (message === "true") {
              setVerifiedUser(true);
            }
            console.log(reqData.email);
            setEmail(reqData.email);
            setNotifications(notifications);
          } else {
            setResAlert(res.data.message);
          }
        } catch (error) {
          setResAlert("Incorrect user credentials");
        }
      }
      loginUser(config);
    }
  }, [reqData]);

  useEffect(() => {
    if (Object.keys(reqData).length === 9 && alertText.length === 0) {
      async function registerUser() {
        const config = {
          headers: { "Content-Type": "application/json" },
        };
        try {
          const res = await axios.post(
            `${BACKEND_PROD_URL}/api/user/register`,
            reqData,
            config
          );
          if (res.status === 200) {
            setOnBoardAlertText("Registered Successfully");
            setTab(0);
            setLoginId("");
            setPassword("");
            setFirstName("");
            setLastName("");
            setEmail("");
            setMobileNo("");
            setGstno("");
            setAddress("");
            setCompany("");
            setPasswordTwo("");
          }
        } catch (error) {
          if (error.response) {
            setAlertText(error.response.data.message);
          } else {
            setAlertText(error.message);
          }
        }
        setLoading(false);
      }
      registerUser();
    }
  }, [reqData]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setOnBoardAlertText("");
    setResAlert("");
    setAlertText("");

    if (tab == 0) {
      if (loading === false) {
        if (
          !String(loginId)
            .toLowerCase()
            .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        ) {
          // setOpen(true);
          setAlertText(`Invalid Email ${loginId}`);
        } else {
          const reqSignInData = {
            email: loginId,
            password: password,
          };
          setReqData(reqSignInData);
          setLoading(true);
        }
      } else {
        setLoading(false);
      }
      setLoading(false);
    } else if (tab == 1) {
      if (loading === false) {
        if (
          !String(email)
            .toLowerCase()
            .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        ) {
          setAlertText("Invalid Email");
        } else if (mobileNo.length !== 10 && !/^\d{10}$/.test(mobileNo)) {
          setAlertText("Mobile Number should be of 10 digits");
        } else if (password.length < 6) {
          setAlertText("Password length should be greater than 6");
        } else if (password !== passwordTwo) {
          setAlertText("Password Mismatched");
        } else {
          const reqSignUpData = {
            firstname: firstName,
            lastname: lastName,
            mobile: mobileNo,
            email: email,
            password: password,
            password2: passwordTwo,
            company: company,
            address: address,
            gstno: gstno,
          };
          setLoading(true);
          setReqData(reqSignUpData);
        }
      }
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="flex flex-col lg:flex-row lg:overflow-hidden relative">
      <div className="w-full  absolute md:top-5 md:left-10 top-5 left-5">
        <a href="/">
          <p className="flex items-center space-x-1 rtl:space-x-reverse">
            <img
              src={logo}
              loading="lazy"
              alt="logo"
              className="h-12 lg:h-14 w-auto"
            />
            {/* <span className="self-center text-lg lg:text-2xl font-semibold whitespace-nowrap ">
              Maili Enterprises
            </span> */}
          </p>
        </a>
      </div>

      <form
        onSubmit={handleSubmit}
        className="w-full flex flex-col gap-8 md:gap-2"
      >
        <div className="flex h-[100vh] lg:h-full flex-col justify-center items-center w-full gap-y-10">
          <div
            className={`w-[90%] ${
              tab === 0 ? "md:w-[60%]" : "md:w-[70%]"
            } flex-col flex gap-8 md:gap-6`}
          >
            <div className="flex flex-col gap-y-4 justify-center items-center">
              <p className="text-[28px] md:text-[40px] leading-tight text-[#303030] font-bold ">
                {tab === 0 && "Welcome Back"}
                {/* {tab === 1 && "Create an account"} */}
              </p>
              <p className="text-center text-[16px]  leading-tight text-[#4F4F4F] ">
                {tab === 0 &&
                  "Welcome back to Maili Enterprises, Please enter your details"}
                {/* {tab === 1 && "Welcome to Maili Enterpraises, Please enter your details"} */}
              </p>
            </div>
            <div className="flex justify-center items-center">
              <div className="bg-[#B7D0E7] rounded-full flex gap-2 p-1 px-2 ">
                <button
                  type="button"
                  onClick={() => navigate("/login")}
                  className={`py-1 px-4 ${
                    tab === 0 ? "bg-[#13528D] text-white rounded-full" : ""
                  }`}
                >
                  Sign in
                </button>
                <button
                  type="button"
                  onClick={() => navigate("/register")}
                  className={`py-1 px-4 ${
                    tab === 1 ? "bg-[#13528D] text-white rounded-full" : ""
                  }`}
                >
                  Sign Up
                </button>
              </div>
            </div>
            {tab === 0 && (
              <>
                <FormControl
                  required
                  sx={{ width: "100%" }}
                  // className="rounded-xl"
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-email">
                    Login ID
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-email"
                    type="text"
                    value={loginId}
                    onChange={(e) => setLoginId(e.target.value)}
                    className="!rounded-xl !text-[14px] !text-[#4F4F4F]"
                    endAdornment={
                      <InputAdornment position="end">
                        <MailOutline />
                      </InputAdornment>
                    }
                    label="Email"
                  />
                </FormControl>
                <FormControl
                  required
                  sx={{ width: "100%" }}
                  // className="rounded-xl"
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="!rounded-xl !text-[14px] !text-[#4F4F4F]"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </>
            )}
            {tab === 1 && (
              <>
                <div className="flex flex-row gap-4">
                  <FormControl
                    required
                    sx={{ width: "100%" }}
                    // className="rounded-xl"
                    variant="outlined"
                  >
                    <InputLabel htmlFor="outlined-adornment-firstName">
                      First Name
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-firstName"
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      className="!rounded-xl !text-[14px] !text-[#4F4F4F]"
                      label="First Name"
                    />
                  </FormControl>
                  <FormControl
                    required
                    sx={{ width: "100%" }}
                    // className="rounded-xl"
                    variant="outlined"
                  >
                    <InputLabel htmlFor="outlined-adornment-lastName">
                      Last Name
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-lastName"
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      className="!rounded-xl !text-[14px] !text-[#4F4F4F]"
                      label="Last Name"
                    />
                  </FormControl>
                </div>
                <div className="flex flex-row gap-4">
                  <FormControl
                    required
                    sx={{ width: "100%" }}
                    // className="rounded-xl"
                    variant="outlined"
                  >
                    <InputLabel htmlFor="outlined-adornment-email">
                      Login ID
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-email"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="!rounded-xl !text-[14px] !text-[#4F4F4F]"
                      endAdornment={
                        <InputAdornment position="end">
                          <MailOutline />
                        </InputAdornment>
                      }
                      label="Email"
                    />
                  </FormControl>
                  <FormControl
                    required
                    sx={{ width: "100%" }}
                    // className="rounded-xl"
                    variant="outlined"
                  >
                    <InputLabel htmlFor="outlined-adornment-mobileNo">
                      Mobile Number
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-mobileNo"
                      type="text"
                      value={mobileNo}
                      onChange={(e) => setMobileNo(e.target.value)}
                      className="!rounded-xl !text-[14px] !text-[#4F4F4F]"
                      endAdornment={
                        <InputAdornment position="end">
                          <Phone />
                        </InputAdornment>
                      }
                      label="Mobile Number"
                    />
                  </FormControl>
                </div>

                <div className="flex flex-row gap-4">
                  <FormControl
                    required
                    sx={{ width: "100%" }}
                    // className="rounded-xl"
                    variant="outlined"
                  >
                    <InputLabel htmlFor="outlined-adornment-gstno">
                      GST Number
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-gstno"
                      type="text"
                      value={gstno}
                      onChange={(e) => setGstno(e.target.value)}
                      className="!rounded-xl !text-[14px] !text-[#4F4F4F]"
                      label="GST Number"
                    />
                  </FormControl>
                  <FormControl
                    required
                    sx={{ width: "100%" }}
                    variant="outlined"
                  >
                    <InputLabel htmlFor="outlined-adornment-address">
                      Address
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-address"
                      type="text"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      className="!rounded-xl !text-[14px] !text-[#4F4F4F]"
                      label="Address"
                    />
                  </FormControl>
                </div>
                <FormControl
                  required
                  sx={{ width: "100%" }}
                  // className="rounded-xl"
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-company">
                    Company
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-company"
                    type="text"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    className="!rounded-xl !text-[14px] !text-[#4F4F4F]"
                    label="Company"
                  />
                </FormControl>
                <div className="flex flex-row gap-4">
                  <FormControl
                    required
                    sx={{ width: "100%" }}
                    // className="rounded-xl"
                    variant="outlined"
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="!rounded-xl !text-[14px] !text-[#4F4F4F]"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                  <FormControl
                    required
                    sx={{ width: "100%" }}
                    // className="rounded-xl"
                    variant="outlined"
                  >
                    <InputLabel htmlFor="outlined-adornment-passwordTwo">
                      Confirm Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-passwordTwo"
                      type={showPassword ? "text" : "password"}
                      value={passwordTwo}
                      onChange={(e) => setPasswordTwo(e.target.value)}
                      className="!rounded-xl !text-[14px] !text-[#4F4F4F]"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Confirm Password"
                    />
                  </FormControl>
                </div>
              </>
            )}
            <Button
              type="submit"
              variant="contained"
              className="w-full !bg-[#13528D] text-white !text-[20px]  !text-semibold !rounded-2xl "
              sx={{ textTransform: "none", padding: "4px" }}
            >
              {tab === 0 ? "Sign In" : "Sign Up"}
            </Button>
          </div>
        </div>
      </form>

      <div className=" rightside crop-image flex flex-col w-full h-[100vh]  items-center transform-[matrix(-1, 0, 0, 1, 0, 0)] bg-[#0d3b66] max-lg:!hidden">
        <img
          className="absolute h-[45vh] top-[25%] left-[40%]"
          src={logosvg1}
          alt=""
        />
      </div>

      <Snackbar
        open={resAlert !== ""}
        autoHideDuration={6000}
        onClose={() => setResAlert("")}
      >
        <Alert
          onClose={() => setResAlert("")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {resAlert}
        </Alert>
      </Snackbar>
      <Snackbar
        open={onboardAlertText !== ""}
        autoHideDuration={6000}
        onClose={() => setOnBoardAlertText("")}
      >
        <Alert
          onClose={() => setOnBoardAlertText("")}
          severity="success"
          sx={{ width: "100%" }}
        >
          {onboardAlertText}
        </Alert>
      </Snackbar>
      <Snackbar
        open={alertText !== ""}
        autoHideDuration={6000}
        onClose={() => setAlertText("")}
      >
        <Alert
          onClose={() => setAlertText("")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {alertText}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Login;
