import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import axios from "axios";
import { BACKEND_PROD_URL } from "../config";

export function EditRowDialog({ open, onClose, row, onSave, displayMessage }) {
  const [editingRow, setEditingRow] = useState(row);

  const [loading, setLoading] = useState(false);

  console.log("editingRow qr number", editingRow.QRNumber);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditingRow((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const payload = {
        qrNumber: String(editingRow.QRNumber).slice(3),
        manufacturedate: editingRow.ManufactureDate,
        expirydate: editingRow.ExpiryDate,
        batchnumber: editingRow.BatchNumber,
        companyname: editingRow.CompanyName,
        serialnumberofthemachinery: editingRow.SerialNumberOfTheMachinery,
        yearofmanufacturing: editingRow.YearOfManufacturing,
        enginenumber: editingRow.EngineNumber,
        chasisnumber: editingRow.ChasisNumber,
        pumpnumber: editingRow.PumpNumber,
        engineislicencevalidity: editingRow.EngineIsLicenceValidity,
        pumpislicencevalidity: editingRow.PumpIsLicenceValidity,
        bislicensevalidity: editingRow.BISLicenseValidity,
        mfgmonthandyear: editingRow.MfgMonthAndYear,
        mrp: editingRow.MRP,
        bisnumberandvalidity: editingRow.BISNumberAndValidity,
        cmlnoanddate: editingRow.CMLNoAndDate,
        bundlelateralsize: editingRow.BundleLateralSize,
      };
      const res = await axios.post(
        `${BACKEND_PROD_URL}/api/privateQrCode/updatePrivateQRDetail`,
        payload
      );
      console.log(res);
      setLoading(false);
      onSave(editingRow);
      displayMessage("Changes saved successfully");
      onClose();
    } catch (error) {
      console.error("Error saving changes:", error);
      displayMessage("Error while saving changes. Please retry/contact admin");
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Row</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="BatchNumber"
          label="Batch Number"
          type="text"
          fullWidth
          variant="standard"
          value={editingRow.BatchNumber}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          name="ExpiryDate"
          label="Expiry Date"
          type="text"
          fullWidth
          variant="standard"
          value={editingRow.ExpiryDate}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          name="ManufactureDate"
          label="Manufacture Date"
          type="text"
          fullWidth
          variant="standard"
          value={editingRow.ManufactureDate}
          onChange={handleInputChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} disabled={loading}>
          {loading && <CircularProgress size="30px" />}
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
