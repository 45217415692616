import React from "react";
import {
  LocationOn,
  Email,
  Call,
  Facebook,
  Twitter,
  Instagram,
  LinkedIn,
} from "@mui/icons-material";
import { navItems } from "./Content";

const Footer = () => {
  return (
    <footer className="bg-gray-900 py-12 px-5 md:px-16 font-sans mt-20">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
        <div>
          <h2 className="text-white text-xl md:text-3xl font-bold mb-4">
            Quick Links
          </h2>
          <ul className="space-y-3">
            {navItems.map((link) => (
              <li key={link}>
                <a
                  className=" text-white hover:text-white text-base transition-all"
                  href={link.src}
                >
                  {link.name}
                </a>
              </li>
            ))}
          </ul>
        </div>

        <div>
          <h2 className="text-white text-xl md:text-3xl font-bold mb-4">
            Services
          </h2>
          <ul className="space-y-3">
            {[
              "Digital Signature Certificates",
              "E-Tokens",
              "Class 3 DSC",
              "DGFT Certificate",
              "Application Development",
            ].map((service) => (
              <li key={service}>
                <a
                  className=" text-white hover:text-white text-base transition-all"
                  href={`/${service.replace(/\s+/g, "-").toLowerCase()}`}
                >
                  {service}
                </a>
              </li>
            ))}
          </ul>
        </div>

        <div>
          <h2 className="text-white text-xl md:text-3xl font-bold mb-4">
            Company
          </h2>
          <ul className="space-y-3">
            {["About", "Privacy Policy", "Terms & Conditions"].map((item) => (
              <li key={item}>
                <a
                  className=" text-white hover:text-white text-base transition-all"
                  href={`/${item.replace(/\s+/g, "-").toLowerCase()}`}
                >
                  {item}
                </a>
              </li>
            ))}
          </ul>
        </div>

        <div>
          <h2 className="text-white text-xl md:text-3xl font-bold mb-4">
            Get in touch
          </h2>
          <ul className="space-y-4">
            <li className=" text-white flex items-center">
              <LocationOn className="mr-2" />
              #2045/A, 2nd Floor, 1st Main Road, RPC Layout, Bangalore
            </li>
            <li className=" text-white flex items-center">
              <Email className="mr-2" />
              mailienterprise@gmail.com
            </li>
            <li className=" text-white flex items-center">
              <Call className="mr-2" />
              <a
                className="hover:text-white transition-all"
                href="tel:+9481777928"
              >
                9481777928, 8970700867
              </a>
            </li>
          </ul>
        </div>
      </div>

      <hr className="my-8 border-gray-700" />

      {/* Footer Bottom */}
      <div className="sm:flex sm:items-center sm:justify-between sm:flex-row">
        <p className=" text-white text-sm sm:order-1">
          © 2024
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="hover:underline mx-1"
            href="#"
          >
            Maili Enterprises
          </a>
          All Rights Reserved.
        </p>
        <div className="flex space-x-5 max-sm:mt-4">
          <a href="#" className=" text-white hover:text-white transition-all">
            <Facebook />
          </a>
          <a href="#" className=" text-white hover:text-white transition-all">
            <Twitter />
          </a>
          <a href="#" className=" text-white hover:text-white transition-all">
            <Instagram />
          </a>
          <a href="#" className=" text-white hover:text-white transition-all">
            <LinkedIn />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
