import React from "react";
import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import Login from "./pages/Login";
import Home from "./pages/Home";
import Report from "./pages/Report";
import PrimaryQR from "./pages/PrimaryQR";
import ContainerQR from "./pages/ContainerQR";
import Product from "./pages/Product";
import ProductReport from "./pages/ProductReport";
import UserReport from "./pages/UserReport";
import UserChangePassword from "./pages/UserChangePassword";
import VerifyDetails from "./pages/VerifyDetails";
import QRCodePrintComponent from "./pages/QRCodePrintComponent";
import GenerateQRPDF from "./pages/GenerateQRPDF";
import { createTheme, ThemeProvider } from "@mui/material";
import { CssBaseline } from "@mui/material";
import AdminUtils from "./pages/AdminUtils";
import LandingPage from "./pages/LandingPage";
import FeaturesPage from "./pages/Features";
import PricingPage from "./pages/PricingPage";

import GetQrDetails from "./pages/GetQrDetails";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0b3b66",
    },
    secondary: {
      main: "#0b3b66",
    },
  },
  typography: {
    fontWeightBold: 700,
  },
});

const App = () => {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/register" element={<Login />} />
            <Route exact path="/" element={<LandingPage />} />
            <Route exact path="/home" element={<Home />} />
            <Route exact path="/primaryQR" element={<PrimaryQR />} />
            <Route exact path="/containerQR" element={<ContainerQR />} />
            <Route exact path="/qr-report" element={<Report />} />
            <Route exact path="/QRCodes" element={<GenerateQRPDF />} />
            <Route exact path="/create-product" element={<Product />} />
            <Route exact path="/user-report" element={<UserReport />} />
            <Route exact path="/product-report" element={<ProductReport />} />
            <Route exact path="/verify-details" element={<VerifyDetails />} />
            <Route exact path="/qr/:qrNumber" element={<GetQrDetails />} />

            <Route
              exact
              path="/change-password"
              element={<UserChangePassword />}
            />
            <Route exact path="/print-qrs" element={<QRCodePrintComponent />} />
            <Route exact path="/admin-utils" element={<AdminUtils />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
};

export default App;
