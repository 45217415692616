import React from "react";
import { motion } from "framer-motion";
import { cards } from "./Content";

const ServiceSection = () => {
  return (
    <div className="bg-blue-50 py-12" id="services">
      <motion.p
        className="text-4xl font-extrabold text-center md:text-4xl  py-5 pb-0"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        Other services
      </motion.p>
      <motion.p
        className="text-center text-md p-1"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        Explore our digital offerings—crafted to simplify your journey!{" "}
      </motion.p>
      <motion.p
        className="text-center pb-5"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        Check out our website to explore all our digital offerings!
      </motion.p>
      <div className="flex items-center justify-center pb-5 ">
        <a href="https://mailienterprises.com" target="_blank">
          <button
            type="button"
            class="text-white text-xl bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg px-5 py-2.5 text-center mr-2 mb-2"
          >
            Explore more
          </button>
        </a>
      </div>{" "}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {cards.map((card) => (
            <motion.div
              key={card.id}
              className="bg-white rounded-xl shadow-2xl p-6 text-center"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              whileHover={{
                scale: 1.05,
                boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div className="flex justify-center mb-4">
                <div className={`${card.bgColor} p-4 rounded-full`}>
                  {card.icon}
                </div>
              </div>
              <h3 className="text-lg font-semibold text-blue-600">
                {card.title}
              </h3>
              <p className="text-md mt-2">{card.description}</p>
              {/* <button className="mt-4 border border-blue-600 text-blue-600 px-4 py-2 rounded-lg hover:bg-blue-600 hover:text-white transition-colors duration-300 ">
                Explore →
              </button> */}
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceSection;
