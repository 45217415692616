import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Typography, Box } from '@mui/material';


import CircularProgress from "@mui/material/CircularProgress";
import { BACKEND_PROD_URL } from "../config";


const fieldsToKeep = {
  manufacturername: 'Manufacturer Name',
  suppliername: 'Supplier Name',
  categoryname: 'Category Name',
  subcategoryname: 'Sub Category Name',
  productname: 'Product Name',
  brandname: 'Brand Name',
  uomname: 'Unit of Measure',
  weight: 'Weight',
  group: 'Group',
  batchnumber: 'Batch Number',
  manfdate: 'Manufacturing Date',
  expdate: 'Expiry Date',
  mrp: 'MRP',
};



const GetQrDetails = () => {
  const { qrNumber } = useParams();
  const [qrDetails, setQrDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchQrDetails = async () => {
      setQrDetails(null);
      setError(null);
      setLoading(true);
      try {
        const response = await axios.get(
          `${BACKEND_PROD_URL}/api/privateQrCode/getPrivateQRDetail/${qrNumber}`
        );
        const data = response.data.data;
        console.log(data)

        const filtered = [];
        
        filtered.push(['QR Number', qrNumber.toString()]);

        for (const key in data) {
          if (
            fieldsToKeep.hasOwnProperty(key) &&
            data[key] !== null &&
            data[key] !== '' &&
            data[key] !== undefined
          ) {
            filtered.push([fieldsToKeep[key], data[key]]);
          }
        }

        if (response.status === 200) {
          setQrDetails(filtered);
        } else {
          setError("Invalid Qr Number");
        }
        console.log(filtered)
      } catch (error) {
        console.log("error catched ", error);
        setError("Invalid Qr Number");
      }
      setLoading(false);
    };

    fetchQrDetails();
  }, [qrNumber]);

  if (loading)
    return (
      <>
        <div className="flex">....Loading Qr Details</div>
        <CircularProgress size={"3rem"} />
      </>
    );

  if (error) return <p className="p-3">{error}</p>;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      {/* Centered Heading */}
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{ padding: '16px', backgroundColor: '#f5f5f5' }}
      >
        QR Details
      </Typography>

      {/* Table Content */}
      <Box sx={{ flex: 1, padding: '16px' }}>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {qrDetails.map(([key, value], index) => (
                <TableRow key={index}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Footer */}
      <Box
        sx={{
          backgroundColor: '#f5f5f5',
          textAlign: 'center',
          padding: '16px',
          marginTop: 'auto',
        }}
      >
        <Typography variant="body2">Made with ❤️ by Maili Enterprises</Typography>
      </Box>
    </Box>
  );
};

export default GetQrDetails;
