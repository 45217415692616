import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import axios from "axios";
import { BACKEND_PROD_URL } from "../config";
import { CircularProgress } from "@mui/material";

const ContactUs = () => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [message, SetMessage] = useState("Message successfully sent");
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  // Close snackbar when clicked or after timeout
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSnackbar(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (
      !userInfo.name ||
      !userInfo.email ||
      !userInfo.phone ||
      !userInfo.message
    ) {
      SetMessage("Please enter all fields");
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 4000);
      setLoading(false);
      return;
    }

    if (!/\S+@\S+\.\S+/.test(userInfo.email)) {
      SetMessage("Please enter a valid email address");
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 4000);
      setLoading(false);
      return;
    }

    try {
      await axios.post(`${BACKEND_PROD_URL}/api/admin/contactUs`, {
        name: userInfo.name,
        email: userInfo.email,
        phone: userInfo.phone,
        message: userInfo.message,
      });
      SetMessage("Message successfully sent");
      setLoading(false);
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 4000);
      setUserInfo({
        name: "",
        phone: "",
        email: "",
        message: "",
      });
    } catch (error) {
      console.error(error);
      SetMessage("Something went wrong. Please try again.");
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 4000);
      setLoading(false);
    }

    setTimeout(() => {
      setShowSnackbar(false);
    }, 4000);
  };

  return (
    <>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
        message={message}
        action={action}
      />
      <section id="contact-us" className="bg-gray-100 py-16 px-5 md:px-16">
        <div className="container mx-auto px-6 lg:px-20">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-800">Contact Us</h2>
            <p className="text-gray-600 mt-2">
              We'd love to hear from you! Reach out to us anytime.
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 ">
          <div>
            <form
              className="bg-white rounded-lg p-8 shadow-2xl"
              onSubmit={handleSubmit} // Ensure form submission is handled
            >
              <div className="mb-4 ">
                <label
                  htmlFor="name"
                  className="block text-gray-700 font-medium mb-2"
                >
                  Your Name
                </label>
                <input
                  value={userInfo.name}
                  onChange={(e) =>
                    setUserInfo((i) => ({ ...i, name: e.target.value }))
                  }
                  type="text"
                  id="name"
                  placeholder="Enter your name"
                  className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-gray-700 font-medium mb-2"
                >
                  Your Email
                </label>
                <input
                  value={userInfo.email}
                  onChange={(e) =>
                    setUserInfo((i) => ({ ...i, email: e.target.value }))
                  }
                  type="email"
                  id="email"
                  placeholder="Enter your email"
                  className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="phone"
                  className="block text-gray-700 font-medium mb-2"
                >
                  Your Phone
                </label>
                <input
                  value={userInfo.phone}
                  type="tel"
                  onChange={(e) =>
                    setUserInfo((i) => ({ ...i, phone: e.target.value }))
                  }
                  id="phone"
                  placeholder="Enter your phone number"
                  className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="message"
                  className="block text-gray-700 font-medium mb-2"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  value={userInfo.message}
                  onChange={(e) =>
                    setUserInfo((i) => ({ ...i, message: e.target.value }))
                  }
                  rows="5"
                  placeholder="Write your message here..."
                  className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                ></textarea>
              </div>

              <button
                type="submit"
                disabled={loading}
                className="bg-blue-500 text-white py-3 px-6 rounded-lg shadow-md hover:bg-blue-600 focus:outline-none disabled:cursor-not-allowed"
              >
                <div className="flex items-center justify-center gap-3">
                  Send message
                  {loading && (
                    <CircularProgress size={24} className="text-white" />
                  )}
                </div>{" "}
              </button>
            </form>
          </div>

          <div className="space-y-8">
            <div className="overflow-hidden rounded-lg shadow-2xl">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2987.7342787486336!2d77.53708582882595!3d12.967783606159838!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3d8b91a7e781%3A0x8dcafaad82a48cc5!2sMaili%20Enterprises!5e0!3m2!1sen!2sin!4v1732724560382!5m2!1sen!2sin"
                width="670"
                height="600"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
