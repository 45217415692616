import { jsPDF } from "jspdf";
import QRCode from "qrcode";
import { PRIVATE_QR_URL } from "../config.js";

const showError = (message, setOpen, setSnackbarMessage) => {
  setOpen(true);
  setSnackbarMessage(message);
  return [];
};


const repeatElement = (array, n, isPrivateQR) => {
  if (!Array.isArray(array) || array.length !== 1 || !isPrivateQR) {
    return array;
  }
  return Array(n).fill(array[0]);
};

// Common function to create a sub-array
export function createSubArray(
  parentArray,
  startSN,
  endSN,
  mrp,
  printMRP,
  setOpen,
  setSnackbarMessage
) {
  console.log("StartSN and endSN", startSN, endSN, parentArray);

  if (
    startSN === undefined ||
    startSN === null ||
    endSN === undefined ||
    endSN === null ||
    startSN.length === 0 ||
    endSN.length === 0 ||
    startSN === -1 ||
    endSN === -1
  ) {
    console.log(startSN, endSN);
    return parentArray;
  }

  console.log(printMRP, mrp.length);
  if (printMRP && mrp.length > 13) {
    return showError(
      "Error: MRP length exceeds the maximum limit of 13 characters. Please shorten the MRP.",
      setOpen,
      setSnackbarMessage
    );
  }

  if (startSN > endSN) {
    return showError(
      "Error: Start sequence number cannot be greater than end sequence number. Please check and correct the range.",
      setOpen,
      setSnackbarMessage
    );
  }

  // Ensure startNo is within bounds
  const startIndex = Math.max(0, startSN);

  // Ensure endNo is within bounds, considering the last element of the parent array
  const endIndex = Math.min(parentArray.length - 1, endSN);

  // Slice the parent array to create the new array
  return parentArray.slice(startIndex, endIndex + 1);
}

// Function to generate PDF (2x1 inch)
export async function generatePDF(
  rows,
  groupName,
  companyName,
  startSN,
  endSN,
  containerEnabled,
  printMRP,
  mrp,
  setPageLoading,
  setOpen,
  setSnackbarMessage,
  isPrivateQR
) {
  setPageLoading(true);
  let qrCodes = createSubArray(
    rows,
    startSN - 1,
    endSN - 1,
    printMRP,
    mrp,
    setOpen,
    setSnackbarMessage
  );
  qrCodes = repeatElement(qrCodes, 2, isPrivateQR)
  const qrCodeWidth = 18; // QR code width in mm
  const qrCodeHeight = 18; // QR code height in mm
  const pageWidth = 100; // Page width in mm
  const pageHeight = 25; // Page height in mm
  const textMargin = -1;
  const fontSize = 10; // Font size for the QR code number
  const qrCodesPerPage = 2; // Number of QR codes per page

  const doc = new jsPDF({
    orientation: "landscape",
    unit: "mm",
    format: [pageWidth, pageHeight],
    compress: true,
  });

  let x = 0, y = 0;

  console.log(qrCodes);
  if (qrCodes === undefined || qrCodes.length === 0) {
    setPageLoading(false);
    return;
  }

  for (let i = 0; i < qrCodes.length; i++) {
    console.log(i);
    const code = qrCodes[i];

    if (i % 2 === 0 && i !== 0) {
      await new Promise((resolve) => setTimeout(resolve, 0));
      doc.addPage();
      x = 0;
      y = 0;
    }
    let qrCodeData;
    let serialNo;

    if (!containerEnabled) {
      qrCodeData = code.QRNumber.slice(3);
      serialNo = String("SN.") + String(code.SerialNumber);
    } else {
      qrCodeData = code.ContainerQRCode.slice(3);
      serialNo = String(code.ContainerRange.slice(2, -2));
    }
    const companyName = "K-KISAN";
    const url = `${PRIVATE_QR_URL}/qr/${qrCodeData}`;
    // Increase canvas resolution
    const canvas = document.createElement("canvas");
    const scale = window.devicePixelRatio || 1;
    canvas.width = qrCodeWidth * scale;
    canvas.height = qrCodeHeight * scale;
    canvas.style.width = qrCodeWidth + "mm";
    canvas.style.height = qrCodeHeight + "mm";
    const context = canvas.getContext("2d");
    context.scale(scale, scale);

    // Render QR code component to the canvas
    QRCode.toCanvas(canvas, isPrivateQR ? url : qrCodeData, {
      width: qrCodeWidth * scale,
      height: qrCodeHeight * scale,
    });

    // Convert canvas to base64 image
    const qrCodeImage = canvas.toDataURL("image/png");

    // Render QR code image to the PDF
    const options = {
      quality: 0.6, // Adjust the quality value as needed
    };
    doc.addImage(
      qrCodeImage,
      "JPEG",
      x,
      y,
      qrCodeWidth,
      qrCodeHeight,
      null,
      "FAST",
      0,
      0,
      options
    );

    const batchNo = code.BatchNumber;
    if (batchNo !== null && batchNo.length !== 0) {
      doc.setFontSize(fontSize - 2);
      doc.text("Batch: " + batchNo, x + 2, y + qrCodeHeight, {
        baseline: "middle",
      });
      doc.text("Batch: " + batchNo, x + 2, y + qrCodeHeight, {
        baseline: "middle",
      });
    }

    let dates = "";
    if (
      code.ManufactureDate !== undefined &&
      code.ManufactureDate !== null &&
      code.ManufactureDate.length !== 0
    )
      dates += "Mfg.Date: " + String(code.ManufactureDate);
    if (
      code.ExpiryDate !== undefined &&
      code.ExpiryDate !== null &&
      code.ExpiryDate.length !== 0 &&
      groupName !== "MICRO IRRIGATION" &&
      groupName !== "TARPAULINS"
    )
      dates += " Exp: " + String(code.ExpiryDate);

    doc.setFontSize(fontSize - 3);
    doc.text(dates, x + 2, y + fontSize - 6.5 + qrCodeHeight, {
      baseline: "middle",
    });
    doc.text(dates, x + 2, y + fontSize - 6.5 + qrCodeHeight, {
      baseline: "middle",
    });

    if (printMRP) {
      if (isPrivateQR) {
        doc.setFontSize(fontSize - 2);
        
        doc.text(
          "Scan for details",
          x + qrCodeWidth + textMargin,
          y + (0.5 * qrCodeHeight) / 3 + 3,
          {
            baseline: "middle",
          }
        );
        doc.text(
          "Scan for details",
          x + qrCodeWidth + textMargin,
          y + (0.5 * qrCodeHeight) / 3 + 3,
          {
            baseline: "middle",
          }
        );


        doc.text(
          "MRP: " + mrp,
          x + qrCodeWidth + textMargin,
          y + (1.5 * qrCodeHeight) / 3 + 1.5,
          {
            baseline: "middle",
          }
        );
        doc.text(
          "MRP: " + mrp,
          x + qrCodeWidth + textMargin,
          y + (1.5 * qrCodeHeight) / 3 + 1.5,
          {
            baseline: "middle",
          }
        );

        // Add QR code number as text below serialNo
        doc.text(
          qrCodeData,
          x + qrCodeWidth + textMargin,
          y + (2 * qrCodeHeight) / 3 + 2,
          {
            baseline: "middle",
          }
        );
        doc.text(
          qrCodeData,
          x + qrCodeWidth + textMargin,
          y + (2 * qrCodeHeight) / 3 + 2,
          {
            baseline: "middle",
          }
        );
        
      }
      else {

        doc.setFontSize(fontSize - 2);
        
        doc.text(
          "K-KISAN",
          x + qrCodeWidth + textMargin,
          y + (0.5 * qrCodeHeight) / 3 + 0.5,
          {
            baseline: "middle",
          }
        );
        doc.text(
          "K-KISAN",
          x + qrCodeWidth + textMargin,
          y + (0.5 * qrCodeHeight) / 3 + 0.5,
          {
            baseline: "middle",
          }
        );


        doc.text(
          "MRP: " + mrp,
          x + qrCodeWidth + textMargin,
          y + (1.5 * qrCodeHeight) / 3 + 1.5,
          {
            baseline: "middle",
          }
        );
        doc.text(
          "MRP: " + mrp,
          x + qrCodeWidth + textMargin,
          y + (1.5 * qrCodeHeight) / 3 + 1.5,
          {
            baseline: "middle",
          }
        );

        // Add QR code number as text below serialNo
        doc.text(
          qrCodeData,
          x + qrCodeWidth + textMargin,
          y + (2 * qrCodeHeight) / 3 + 2,
          {
            baseline: "middle",
          }
        );
        doc.text(
          qrCodeData,
          x + qrCodeWidth + textMargin,
          y + (2 * qrCodeHeight) / 3 + 2,
          {
            baseline: "middle",
          }
        );
        
        // Add serialNo as text below companyName
        doc.text(
          serialNo,
          x + qrCodeWidth + textMargin,
          y + (1 * qrCodeHeight) / 3 + 1,
          {
            baseline: "middle",
          }
        );

        doc.text(
          serialNo,
          x + qrCodeWidth + textMargin,
          y + (1 * qrCodeHeight) / 3 + 1,
          {
            baseline: "middle",
          }
        );

      }
    } else {
      if(isPrivateQR) {

        doc.setFontSize(fontSize);

        // Add QR code number as text below serialNo
        doc.text(
          qrCodeData,
          x + qrCodeWidth + textMargin,
          y + (2 * qrCodeHeight) / 3,
          {
            baseline: "middle",
          }
        );
        doc.text(
          qrCodeData,
          x + qrCodeWidth + textMargin,
          y + (2 * qrCodeHeight) / 3,
          {
            baseline: "middle",
          }
        );


        doc.text("Scan for details", x + qrCodeWidth + textMargin, y + qrCodeHeight / 3, {
          baseline: "middle",
        });

        doc.text("Scan for details", x + qrCodeWidth + textMargin, y + qrCodeHeight / 3, {
          baseline: "middle",
        });    

      }
      else{
        doc.setFontSize(fontSize);

        // Add QR code number as text below serialNo
        doc.text(
          qrCodeData,
          x + qrCodeWidth + textMargin,
          y + (2 * qrCodeHeight) / 3 + 1,
          {
            baseline: "middle",
          }
        );
        doc.text(
          qrCodeData,
          x + qrCodeWidth + textMargin,
          y + (2 * qrCodeHeight) / 3 + 1,
          {
            baseline: "middle",
          }
        );
  
        doc.text("K-KISAN", x + qrCodeWidth + textMargin, y + qrCodeHeight / 3, {
          baseline: "middle",
        });

        doc.text("K-KISAN", x + qrCodeWidth + textMargin, y + qrCodeHeight / 3, {
          baseline: "middle",
        });


        // Add serialNo as text below companyName
        doc.text(
          serialNo,
          x + qrCodeWidth + textMargin,
          y + (1.5 * qrCodeHeight) / 3 + 0.5,
          {
            baseline: "middle",
          }
        );

        // Add serialNo as text below companyName
        doc.text(
          serialNo,
          x + qrCodeWidth + textMargin,
          y + (1.5 * qrCodeHeight) / 3 + 0.5,
          {
            baseline: "middle",
          }
        );
      }
    }

    if (i % 2 === 0) {
      x += qrCodeWidth * 3;
    } else {
      x = 0;
      y += qrCodeHeight;
    }
  }
  setPageLoading(false);
  doc.save("QRDetails(2x1Inch).pdf");
}

// Function to generate v2 PDF (A4 size)
export async function generatev2PDF(
  rows,
  groupName,
  companyName,
  startSN,
  endSN,
  containerEnabled,
  printMRP,
  mrp,
  setPageLoading,
  setOpen,
  setSnackbarMessage,
  isPrivateQR
) {
  setPageLoading(true);
  let qrCodes = createSubArray(
    rows,
    startSN - 1,
    endSN - 1,
    printMRP,
    mrp,
    setOpen,
    setSnackbarMessage
  );
  qrCodes = repeatElement(qrCodes, 33, isPrivateQR)
  const qrCodeWidth = 18; // QR code width in mm
  const qrCodeHeight = 18; // QR code height in mm
  const pageWidth = 50; // Page width in mm
  const pageHeight = 25; // Page height in mm
  const textMargin = -1;
  const fontSize = 10; // Font size for the QR code number
  const containerWidth = 210;

  const doc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: "a4",
    compress: true,
  });

  let x = 0; // Horizontal position
  let y = 0; // Vertical position
  let companyNameWidth = doc.getTextWidth(companyName);
  if (qrCodes === undefined || qrCodes.length === 0) {
    setPageLoading(false);
    return;
  }

  for (let i = 0; i < qrCodes.length; i++) {
    const code = qrCodes[i];
    console.log(x, y);
    if (y + pageHeight > doc.internal.pageSize.height) {
      // Move to   the next page if the container exceeds the page height
      await new Promise((resolve) => setTimeout(resolve, 0));
      doc.addPage();
      x = 0;
      y = 0;
    }
    let qrCodeData;
    let serialNo;
    if (!containerEnabled) {
      qrCodeData = code.QRNumber.slice(3);
      serialNo = String("SN.") + String(code.SerialNumber);
    } else {
      qrCodeData = code.ContainerQRCode.slice(3);
      serialNo = String(code.ContainerRange.slice(2, -2));
    }

    const url = `${PRIVATE_QR_URL}/qr/${qrCodeData}`;

    // Increase canvas resolution
    const canvas = document.createElement("canvas");
    const scale = window.devicePixelRatio || 1;
    canvas.width = qrCodeWidth * scale;
    canvas.height = qrCodeHeight * scale;
    canvas.style.width = qrCodeWidth + "mm";
    canvas.style.height = qrCodeHeight + "mm";
    const context = canvas.getContext("2d");
    context.scale(scale, scale);

    let j = 2;
    QRCode.toCanvas(canvas, isPrivateQR ? url : qrCodeData, {
      width: qrCodeWidth * scale,
      height: qrCodeHeight * scale,
    });

    // Convert canvas to base64 image
    const qrCodeImage = canvas.toDataURL("image/png");

    // Render QR code image to the PDF
    const options = {
      quality: 0.6, // Adjust the quality value as needed
    };
    doc.addImage(
      qrCodeImage,
      "JPEG",
      x,
      y + j,
      qrCodeWidth,
      qrCodeHeight,
      null,
      "FAST",
      0,
      0,
      options
    );

    if (printMRP) {
      if(isPrivateQR){

        doc.setFontSize(fontSize - 2);
        doc.text(
          "Scan for details",
          x + qrCodeWidth + textMargin,
          y + j + (0.5 * qrCodeHeight) / 3 + 2,
          {
            baseline: "middle",
            y,
          }
        );
        doc.text(
          "Scan for details",
          x + qrCodeWidth + textMargin,
          y + j + (0.5 * qrCodeHeight) / 3 + 2,
          {
            baseline: "middle",
            y,
          }
        );
  
        doc.text(
          "MRP: " + mrp,
          x + qrCodeWidth + textMargin,
          y + j + (1.5 * qrCodeHeight) / 3 + 1,
          {
            baseline: "middle",
          }
        );
        doc.text(
          "MRP: " + mrp,
          x + qrCodeWidth + textMargin,
          y + j + (1.5 * qrCodeHeight) / 3 + 1,
          {
            baseline: "middle",
          }
        );
  
        // Add QR code number as text below serialNo
        doc.text(
          qrCodeData,
          x + qrCodeWidth + textMargin,
          y + j + (2 * qrCodeHeight) / 3 + 1.5,
          {
            baseline: "middle",
          }
        );

        doc.text(
          qrCodeData,
          x + qrCodeWidth + textMargin,
          y + j + (2 * qrCodeHeight) / 3 + 1.5,
          {
            baseline: "middle",
          }
        );  
      }
      else{
        doc.setFontSize(fontSize - 2);
        doc.text(
          "K-KISAN",
          x + qrCodeWidth + textMargin,
          y + j + (0.5 * qrCodeHeight) / 3 + 0.5,
          {
            baseline: "middle",
            y,
          }
        );
        doc.text(
          "K-KISAN",
          x + qrCodeWidth + textMargin,
          y + j + (0.5 * qrCodeHeight) / 3 + 0.5,
          {
            baseline: "middle",
            y,
          }
        );

        // Add serialNo as text below companyName
        doc.text(
          serialNo,
          x + qrCodeWidth + textMargin,
          y + j + (1 * qrCodeHeight) / 3 + 1,
          {
            baseline: "middle",
          }
        );
        doc.text(
          serialNo,
          x + qrCodeWidth + textMargin,
          y + j + (1 * qrCodeHeight) / 3 + 1,
          {
            baseline: "middle",
          }
        );
  
        doc.text(
          "MRP: " + mrp,
          x + qrCodeWidth + textMargin,
          y + j + (1.5 * qrCodeHeight) / 3 + 1.5,
          {
            baseline: "middle",
          }
        );
        doc.text(
          "MRP: " + mrp,
          x + qrCodeWidth + textMargin,
          y + j + (1.5 * qrCodeHeight) / 3 + 1.5,
          {
            baseline: "middle",
          }
        );
  
        // Add QR code number as text below serialNo
        doc.text(
          qrCodeData,
          x + qrCodeWidth + textMargin,
          y + j + (2 * qrCodeHeight) / 3 + 2,
          {
            baseline: "middle",
          }
        );

        doc.text(
          qrCodeData,
          x + qrCodeWidth + textMargin,
          y + j + (2 * qrCodeHeight) / 3 + 2,
          {
            baseline: "middle",
          }
        );
      }
    } else {
      if(isPrivateQR){
        doc.setFontSize(fontSize);
        doc.text(
          "Scan for details",
          x + qrCodeWidth + textMargin,
          y + j + qrCodeHeight / 3,
          {
            baseline: "middle",
          }
        );
        doc.text(
          "Scan for details",
          x + qrCodeWidth + textMargin,
          y + j + qrCodeHeight / 3,
          {
            baseline: "middle",
          }
        );
  
        // Add QR code number as text below serialNo
        doc.text(
          qrCodeData,
          x + qrCodeWidth + textMargin,
          y + j + (2 * qrCodeHeight) / 3 + 1,
          {
            baseline: "middle",
          }
        );
        doc.text(
          qrCodeData,
          x + qrCodeWidth + textMargin,
          y + j + (2 * qrCodeHeight) / 3 + 1,
          {
            baseline: "middle",
          }
        );
      }
      else{
        doc.setFontSize(fontSize);
        doc.text(
          "K-KISAN",
          x + qrCodeWidth + textMargin,
          y + j + qrCodeHeight / 3,
          {
            baseline: "middle",
          }
        );
        doc.text(
          "K-KISAN",
          x + qrCodeWidth + textMargin,
          y + j + qrCodeHeight / 3,
          {
            baseline: "middle",
          }
        );

        // Add serialNo as text below companyName
        doc.text(
          serialNo,
          x + qrCodeWidth + textMargin,
          y + j + (1.5 * qrCodeHeight) / 3 + 0.5,
          {
            baseline: "middle",
          }
        );
        doc.text(
          serialNo,
          x + qrCodeWidth + textMargin,
          y + j + (1.5 * qrCodeHeight) / 3 + 0.5,
          {
            baseline: "middle",
          }
        );
  
        // Add QR code number as text below serialNo
        doc.text(
          qrCodeData,
          x + qrCodeWidth + textMargin,
          y + j + (2 * qrCodeHeight) / 3 + 1,
          {
            baseline: "middle",
          }
        );
        doc.text(
          qrCodeData,
          x + qrCodeWidth + textMargin,
          y + j + (2 * qrCodeHeight) / 3 + 1,
          {
            baseline: "middle",
          }
        );
      }

    }
    doc.setFontSize(fontSize);

    const batchNo = code.BatchNumber;
    if (batchNo !== null && batchNo.length !== 0) {
      doc.setFontSize(fontSize);
      doc.text("Batch: " + batchNo, x + 2, y + j + qrCodeHeight, {
        baseline: "middle",
      });
      doc.text("Batch: " + batchNo, x + 2, y + j + qrCodeHeight, {
        baseline: "middle",
      });
    }
    let dates = "";
    if (
      code.ManufactureDate !== undefined &&
      code.ManufactureDate !== null &&
      code.ManufactureDate.length !== 0
    )
      dates += "Mfg.Date: " + String(code.ManufactureDate);
    if (
      code.ExpiryDate !== undefined &&
      code.ExpiryDate !== null &&
      code.ExpiryDate.length !== 0 &&
      groupName !== "MICRO IRRIGATION" &&
      groupName !== "TARPAULINS"
    )
      dates += " Exp: " + String(code.ExpiryDate);

    doc.setFontSize(fontSize - 3);
    doc.text(dates, x + 2, y + fontSize + j + qrCodeHeight - 6.5, {
      baseline: "middle",
    });
    doc.text(dates, x + 2, y + fontSize + j + qrCodeHeight - 6.5, {
      baseline: "middle",
    });

    x = x + pageWidth + 20;
    if (x + pageWidth > containerWidth) {
      // Move to the next row if the container exceeds the page width
      x = 0;
      y += pageHeight;
    }
  }
  setPageLoading(false);
  doc.save("QRDetails(A4).pdf");
}

// Function to generate 1 QR code only in PDF
export async function generatev3PDF(
  rows,
  groupName,
  companyName,
  startSN,
  endSN,
  containerEnabled,
  printMRP,
  mrp,
  setPageLoading,
  setOpen,
  setSnackbarMessage,
  isPrivateQR
) {
  setPageLoading(true);
  let qrCodes = createSubArray(
    rows,
    startSN - 1,
    endSN - 1,
    printMRP,
    mrp,
    setOpen,
    setSnackbarMessage
  );
  qrCodes = repeatElement(qrCodes, 4, isPrivateQR)
  const qrCodeWidth = 20; // QR code width in mm
  const qrCodeHeight = 20; // QR code height in mm
  const pageWidth = 100; // Page width in mm
  const pageHeight = 25; // Page height in mm
  const textMargin = -1;
  const fontSize = 10; // Font size for the QR code number

  const doc = new jsPDF({
    orientation: "landscape",
    unit: "mm",
    format: [pageWidth, pageHeight],
    compress: true,
  });

  let x = 0; // Horizontal position
  let y = 0; // Vertical position

  if (qrCodes === undefined || qrCodes.length === 0) {
    setPageLoading(false);
    return;
  }

  for (let i = 0; i < qrCodes.length; i++) {
    const code = qrCodes[i];

    if (i % 4 === 0 && i !== 0) {
      await new Promise((resolve) => setTimeout(resolve, 0));
      doc.addPage();
      x = 0;
      y = 0;
    }
    let qrCodeData;
    let serialNo;

    if (!containerEnabled) {
      qrCodeData = code.QRNumber.slice(3);
      serialNo = String("SN.") + String(code.SerialNumber);
    } else {
      qrCodeData = code.ContainerQRCode.slice(3);
      serialNo = String(code.ContainerRange.slice(2, -2));
    }

    // Increase canvas resolution
    const canvas = document.createElement("canvas");
    const scale = window.devicePixelRatio || 1;
    canvas.width = qrCodeWidth * scale;
    canvas.height = qrCodeHeight * scale;
    canvas.style.width = qrCodeWidth + "mm";
    canvas.style.height = qrCodeHeight + "mm";
    const context = canvas.getContext("2d");
    context.scale(scale, scale);

    const url = `${PRIVATE_QR_URL}/qr/${qrCodeData}`;

    // Render QR code component to the canvas
    QRCode.toCanvas(canvas, isPrivateQR ? url : qrCodeData, {
      width: qrCodeWidth * scale,
      height: qrCodeHeight * scale,
    });

    // Convert canvas to base64 image
    const qrCodeImage = canvas.toDataURL("image/png");

    // Render QR code image to the PDF
    const options = {
      quality: 0.6, // Adjust the quality value as needed
    };
    doc.addImage(
      qrCodeImage,
      "JPEG",
      x,
      y,
      qrCodeWidth,
      qrCodeHeight,
      null,
      "FAST",
      0,
      0,
      options
    );

    doc.setFontSize(fontSize - 2.3);
    doc.text(qrCodeData, x + 2, y + qrCodeHeight, {
      baseline: "middle",
    });
    doc.text(qrCodeData, x + 2, y + qrCodeHeight, {
      baseline: "middle",
    });

    x += qrCodeWidth * 1.3;
  }
  setPageLoading(false);
  doc.save("QRDetails(1x1Inch).pdf");
}
