import React from "react";
import { NavLink } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import AdminAuth from "../hooks/AdminAuth";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router";
import { Tooltip, tooltipClasses } from "@mui/material";
import { styled } from "@mui/system";
import logo from "../assets/logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "black",
    fontSize: "12px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "black",
  },
});

function Topbar() {
  const { currentUser, setIsAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    navigate("/");
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <NavLink
            to="/home"
            style={{
              textDecoration: "none",
              color: "#000",
              // backgroundColor: "#FFF",
            }}
          >
            <p
              href="/home"
              className="flex items-center space-x-1 rtl:space-x-reverse"
              style={{ marginRight: "25px" }}
            >
              <img
                loading="lazy"
                src={logo}
                alt="logo"
                className="h-12 text-white lg:h-14 w-auto"
              />
            </p>
          </NavLink>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <CustomTooltip
              title="Click To Generate Primary or Product QR Codes."
              arrow
            >
              <NavLink
                to="/primaryQR"
                style={{ textDecoration: "none", color: "#FFF" }}
              >
                {({ isActive }) => (
                  <Button
                    sx={{ my: 2, color: "white", display: "block" }}
                    style={{
                      backgroundColor: isActive ? "#000" : "",
                    }}
                  >
                    Primary-QR
                  </Button>
                )}
              </NavLink>
            </CustomTooltip>
            <CustomTooltip
              title="Click to Generate Secondary or Container QR Codes"
              arrow
            >
              <NavLink
                to="/containerQR"
                style={{ textDecoration: "none", color: "#FFF" }}
              >
                {({ isActive }) => (
                  <Button
                    sx={{ my: 2, color: "white", display: "block" }}
                    style={{
                      backgroundColor: isActive ? "#000" : "",
                    }}
                  >
                    {" "}
                    Container-QR
                  </Button>
                )}
              </NavLink>
            </CustomTooltip>
            <CustomTooltip title="Click to Know QR Codes History" arrow>
              <NavLink
                to="/qr-report"
                style={{ textDecoration: "none", color: "#FFF" }}
              >
                {({ isActive }) => (
                  <Button
                    sx={{ my: 2, color: "white", display: "block" }}
                    style={{
                      backgroundColor: isActive ? "#000" : "",
                    }}
                  >
                    {" "}
                    QR-Report
                  </Button>
                )}
              </NavLink>
            </CustomTooltip>
            <AdminAuth>
              <NavLink
                to="/create-product"
                style={{ textDecoration: "none", color: "#FFF" }}
              >
                {({ isActive }) => (
                  <Button
                    sx={{ my: 2, color: "white", display: "block" }}
                    style={{
                      backgroundColor: isActive ? "#000" : "",
                    }}
                  >
                    {" "}
                    Create-Product
                  </Button>
                )}
              </NavLink>
              <NavLink
                to="/user-report"
                style={{ textDecoration: "none", color: "#FFF" }}
              >
                {({ isActive }) => (
                  <Button
                    sx={{ my: 2, color: "white", display: "block" }}
                    style={{
                      backgroundColor: isActive ? "#000" : "",
                    }}
                  >
                    {" "}
                    User-Report
                  </Button>
                )}
              </NavLink>
              <NavLink
                to="/product-report"
                style={{ textDecoration: "none", color: "#FFF" }}
              >
                {({ isActive }) => (
                  <Button
                    sx={{ my: 2, color: "white", display: "block" }}
                    style={{
                      backgroundColor: isActive ? "#000" : "",
                    }}
                  >
                    {" "}
                    Product-Report
                  </Button>
                )}
              </NavLink>
              <NavLink
                to="/admin-utils"
                style={{ textDecoration: "none", color: "#FFF" }}
              >
                {({ isActive }) => (
                  <Button
                    sx={{ my: 2, color: "white", display: "block" }}
                    style={{
                      backgroundColor: isActive ? "#000" : "",
                    }}
                  >
                    {" "}
                    Admin-Utils
                  </Button>
                )}
              </NavLink>
            </AdminAuth>
            <CustomTooltip
              title="Click to Verify Individual Primary/Secondary QR Codes"
              arrow
            >
              <NavLink
                to="/verify-details"
                style={{ textDecoration: "none", color: "#FFF" }}
              >
                {({ isActive }) => (
                  <Button
                    sx={{ my: 2, color: "white", display: "block" }}
                    style={{
                      backgroundColor: isActive ? "#000" : "",
                    }}
                  >
                    VERIFY-DETAILS
                  </Button>
                )}
              </NavLink>
            </CustomTooltip>
            {currentUser.userrole === "CLIENT" ? (
              <CustomTooltip
                title="Click to Change Your Current Login Password"
                arrow
              >
                <NavLink
                  to="/change-password"
                  style={{ textDecoration: "none", color: "#FFF" }}
                >
                  {({ isActive }) => (
                    <Button
                      sx={{ my: 2, color: "white", display: "block" }}
                      style={{
                        backgroundColor: isActive ? "#000" : "",
                      }}
                    >
                      {" "}
                      Change Password
                    </Button>
                  )}
                </NavLink>
              </CustomTooltip>
            ) : null}

            <NavLink
              to="/login"
              style={{ textDecoration: "none", color: "#FFF" }}
            >
              <Button
                onClick={handleLogout}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                LOGOUT
              </Button>
            </NavLink>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Topbar;
// const menuItems = [
//   {
//     to: "/primaryQR",
//     title: "Click To Generate Primary or Product QR Codes.",
//     label: "Primary-QR",
//   },
//   {
//     to: "/containerQR",
//     title: "Click to Generate Secondary or Container QR Codes",
//     label: "Container-QR",
//   },
//   {
//     to: "/qr-report",
//     title: "Click to Know QR Codes History",
//     label: "QR-Report",
//   },
//   {
//     to: "/create-product",
//     title: "Create Product",
//     label: "Create-Product",
//     admin: true,
//   },
//   {
//     to: "/user-report",
//     title: "User Report",
//     label: "User-Report",
//     admin: true,
//   },
//   {
//     to: "/product-report",
//     title: "Product Report",
//     label: "Product-Report",
//     admin: true,
//   },
//   {
//     to: "/admin-utils",
//     title: "Admin Utils",
//     label: "Admin-Utils",
//     admin: true,
//   },
//   {
//     to: "/verify-details",
//     title: "Click to Verify Individual Primary/Secondary QR Codes",
//     label: "VERIFY-DETAILS",
//   },
//   {
//     to: "/change-password",
//     title: "Click to Change Your Current Login Password",
//     label: "Change Password",
//     client: true,
//   },
// ];

// function Topbar() {
//   const { currentUser, setIsAuthenticated } = useAuth();
//   const navigate = useNavigate();
//   const [anchorEl, setAnchorEl] = useState(null);

//   const handleLogout = () => {
//     localStorage.removeItem("token");
//     setIsAuthenticated(false);
//     navigate("/");
//   };

//   const handleMenuOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };

//   return (
//     <AppBar position="static ">
//       <Container maxWidth="xl">
//         <Toolbar disableGutters>
//           <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
//             <IconButton
//               size="large"
//               aria-label="menu"
//               aria-controls="menu-appbar"
//               aria-haspopup="true"
//               onClick={handleMenuOpen}
//               color="inherit"
//             >
//               <MenuIcon />
//             </IconButton>
//             <NavLink to="/" style={{ textDecoration: "none", color: "#000" }}>
//               <p
//                 href="/"
//                 className="flex items-center space-x-1 rtl:space-x-reverse"
//                 style={{ marginRight: "25px" }}
//               >
//                 <img
//                   loading="lazy"
//                   src={logo}
//                   alt="logo"
//                   className="h-12 text-white lg:h-14 w-auto"
//                 />
//               </p>
//             </NavLink>
//             <Menu
//               id="menu-appbar"
//               anchorEl={anchorEl}
//               anchorOrigin={{
//                 vertical: "top",
//                 horizontal: "right",
//               }}
//               keepMounted
//               transformOrigin={{
//                 vertical: "top",
//                 horizontal: "right",
//               }}
//               open={Boolean(anchorEl)}
//               onClose={handleMenuClose}
//             >
//               {menuItems.map(
//                 (item) =>
//                   (!item.admin || currentUser.userrole === "ADMIN") &&
//                   (!item.client || currentUser.userrole === "CLIENT") && (
//                     <MenuItem key={item.to} onClick={handleMenuClose}>
//                       <NavLink
//                         to={item.to}
//                         style={{ textDecoration: "none", color: "#000" }}
//                       >
//                         {item.label}
//                       </NavLink>
//                     </MenuItem>
//                   )
//               )}
//               <MenuItem onClick={handleLogout}>
//                 <NavLink
//                   to="/login"
//                   style={{ textDecoration: "none", color: "#000" }}
//                 >
//                   LOGOUT
//                 </NavLink>
//               </MenuItem>
//             </Menu>
//           </Box>

//           <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
//             {menuItems.map(
//               (item) =>
//                 (!item.admin || currentUser.userrole === "ADMIN") &&
//                 (!item.client || currentUser.userrole === "CLIENT") && (
//                   <NavLink
//                     key={item.to}
//                     to={item.to}
//                     style={{ textDecoration: "none", color: "#FFF" }}
//                   >
//                     {({ isActive }) => (
//                       <Button
//                         sx={{ my: 2, color: "white", display: "block" }}
//                         style={{
//                           backgroundColor: isActive ? "#000" : "",
//                         }}
//                       >
//                         {item.label}
//                       </Button>
//                     )}
//                   </NavLink>
//                 )
//             )}

//             <NavLink
//               to="/login"
//               style={{ textDecoration: "none", color: "#FFF" }}
//             >
//               <Button
//                 onClick={handleLogout}
//                 sx={{ my: 2, color: "white", display: "block" }}
//               >
//                 LOGOUT
//               </Button>
//             </NavLink>
//           </Box>
//         </Toolbar>
//       </Container>
//     </AppBar>
//   );
// }

// export default Topbar;
