import React, { useState } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TableHead from "@mui/material/TableHead";
import Topbar from "../components/Topbar";
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";
import { BACKEND_PROD_URL } from "../config";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import UserGuard from "../hooks/UserGuard";
import axios from "axios";
import { CSVLink } from "react-csv";
import useAuth from "../hooks/useAuth";
import AdminAuth from "../hooks/AdminAuth";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import Switch from "@mui/material/Switch";
import Skeleton from "@mui/material/Skeleton";
import Loading from "../components/Loading";
import { EditRowDialog } from "../components/EditRowDialog";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";
import { generatePDF, generatev2PDF, generatev3PDF } from "../utils/pdfUtils";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const Report = () => {

  const [fromDate, setFromDate] = useState(
    moment(moment.tz("Asia/Kolkata").format("YYYY-MM-DD")).startOf("day")
  );
  const [toDate, setToDate] = useState(
    moment(moment.tz("Asia/Kolkata").format("YYYY-MM-DD")).endOf("day")
  );

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [companyList, setCompanyList] = React.useState([]);
  const [companyName, setCompanyName] = React.useState("");
  const [productList, setProductList] = React.useState([]);
  const [productAlias, setProductAlias] = React.useState("");
  const [product, setProduct] = React.useState("");
  const [reqData, setReqData] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState([]);
  const { currentUser } = useAuth();

  const [EditRowDialogOpen, setEditRowDialogOpen] = useState(false);
  const [editingRow, setEditingRow] = useState(null);

  const handleEditRowDialog = (row) => {
    console.log(row)
    setEditingRow(row);
    setEditRowDialogOpen(true);
  };

  const handleCloseEditRowDialog = () => {
    setEditRowDialogOpen(false);
    setEditingRow(null);
  };

  const handleSaveEditRowDialog = (updatedRow) => {
    console.log(updatedRow)
    setRows(
      rows.map((row) => (row.QRNumber === updatedRow.QRNumber ? updatedRow : row))
    );
  };

  const displayMessage = (message) => {
    setOpen(true);
    setSnackbarMessage(message);
  }

  const [privateQrReport, setPrivateQrReport] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [filter, setFilter] = React.useState("");

  const [privateApiCalled, setPrivateAPiCalled] = useState(false);
  const [containerEnabled, setContainerEnabled] = React.useState(false);
  const [batchNumber, setBatchNumber] = React.useState("");
  const [groupName, setGroupName] = React.useState("");
  const [apiProductList, setApiProductList] = React.useState([]);
  const [reqQRapiData, setReqQRapiData] = React.useState({});
  const [openEditForm, setOpenEditForm] = React.useState(false);
  const [editFormValues, setEditFormValues] = React.useState({});
  const [reqCallApiData, setReqCallApiData] = React.useState({});
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [printqrsdialog, setPrintqrsdialog] = useState(false);
  const [startSN, setStartSN] = useState(null);
  const [endSN, setEndSN] = useState(null);
  const [printMRP, setPrintMRP] = useState(false);
  const [mrp, setMRP] = useState("");

  const generatePrivateQR = () => {
    setPrivateQrReport(!privateQrReport);
  };

  const handleActivateQRCode = (row) => {
    setSelectedRow(row);
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const groupList = [
    { id: 1, name: "SEEDS" },
    { id: 2, name: "FERTILIZER" },
    { id: 3, name: "PESTICIDES" },
    { id: 4, name: "CHEMICALS" },
    { id: 5, name: "Farm Machinery Part-A" },
    { id: 6, name: "Farm Machinery Part-B" },
    { id: 7, name: "Farm Machinery Part-C" },
    { id: 8, name: "TARPAULINS" },
    { id: 9, name: "MICRO IRRIGATION" },
  ];

  const handleGroupChange = (e, groupname) => {
    if (groupname !== null) {
      setGroupName(groupname);
      setRows([]);
      setProductAlias("");
      setProductList([]);
      console.log(groupname);
    }
  };

  const handleEdit = (row) => {
    console.log(row);
    setEditFormValues(row);
    setOpenEditForm(true);
  };

  const handleClickPrintQRS = () => {
    setPrintqrsdialog(true);
  };

  const handleClosePrintQRS = () => {
    setPrintqrsdialog(false);
  };

  const handleSaveForm = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    if (groupName === "Farm Machinery Part-A") {
      editFormValues.MachineSerialNumber = formData.get("machineSerialNumber");
      editFormValues.YearOfManufacture = formData.get("yearOfManufacturing");
      editFormValues.MRP = formData.get("mrp");
    } else if (groupName === "Farm Machinery Part-B") {
      editFormValues.MachineSerialNumber = formData.get("machineSerialNumber");
      editFormValues.EngineNumber = formData.get("engineNumber");
      editFormValues.ChasisNumber = formData.get("chasisNumber");
      editFormValues.YearOfManufacture = formData.get("yearOfManufacturing");
      editFormValues.MRP = formData.get("mrp");
    } else if (groupName === "Farm Machinery Part-C") {
      editFormValues.MachineSerialNumber = formData.get("machineSerialNumber");
      editFormValues.EngineNumber = formData.get("engineNumber");
      editFormValues.PumpNumber = formData.get("pumpNumber");
      editFormValues.EngineIsLicenceValidity = formData.get(
        "engineIsLicenceValidity"
      );
      editFormValues.PumpIsLicenceValidity = formData.get(
        "pumpIsLicenceValidity"
      );
      editFormValues.YearOfManufacture = formData.get("yearOfManufacturing");
      editFormValues.MRP = formData.get("mrp");
    }

    setOpenEditForm(false);
  };

  const handleCallAPI = (row) => {
    // console.log("row",row)
    setReqCallApiData({});
    let tempApiDetails = {};
    if (groupName === "Farm Machinery Part-A") {
      tempApiDetails = {
        group: groupName,
        qrnumber: row.QRNumber,
        machineserialnumber: row.MachineSerialNumber,
        yearofmanufacture: row.YearOfManufacture,
        mrp: row.MRP,
      };
    } else if (groupName === "Farm Machinery Part-B") {
      tempApiDetails = {
        group: groupName,
        qrnumber: row.QRNumber,
        machineserialnumber: row.MachineSerialNumber,
        yearofmanufacture: row.YearOfManufacture,
        mrp: row.MRP,
        enginenumber: row.EngineNumber,
        chasisnumber: row.ChasisNumber,
      };
    } else if (groupName === "Farm Machinery Part-C") {
      tempApiDetails = {
        group: groupName,
        qrnumber: row.QRNumber,
        machineserialnumber: row.MachineSerialNumber,
        yearofmanufacture: row.YearOfManufacture,
        mrp: row.MRP,
        enginenumber: row.EngineNumber,
        pumpnumber: row.PumpNumber,
        engineislicencevalidity: row.EngineIsLicenceValidity,
        pumpislicencevalidity: row.PumpIsLicenceValidity,
      };
    }
    setReqCallApiData(tempApiDetails);
    setOpenPreview(false);
    console.log(tempApiDetails);
  };

  const handleBatchNumberChange = (event) => {
    setBatchNumber(event.target.value);
  };

  const handleStartSNChange = (event) => {
    setStartSN(event.target.value);
  };

  const handleEndSNChange = (event) => {
    setEndSN(event.target.value);
  };

  const handleMRPChange = (event) => {
    setMRP(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    console.log("Checkbox", event.target.checked);
    setContainerEnabled(event.target.checked);
  };

  const handlePrintMRP = (event) => {
    setPrintMRP(event.target.checked);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  React.useEffect(() => {
    async function getCompanyList() {
      const token = localStorage.getItem("token");
      const configs = {
        headers: { Authorization: `Bearer ${token}` },
      };
      try {
        const res = await axios.get(
          `${BACKEND_PROD_URL}/api/admin/getCompanyList`,
          configs
        );
        console.log(res);
        if (res.status === 200) {
          setCompanyList(res.data);
        } else {
          setOpen(true);
          setSnackbarMessage("Unknown error occured");
        }
      } catch (error) {
        console.log(error);
        if (error.response) {
          setOpen(true);
          setSnackbarMessage(error.response.data.message);
        } else {
          setOpen(true);
          setSnackbarMessage(error.message);
        }
      }
    }
    if (currentUser.userrole === "ADMIN") getCompanyList();
  }, []);

  React.useEffect(() => {
    if (currentUser.userrole === "CLIENT") {
      setCompanyName(currentUser.company);
    }
  }, []);

  React.useEffect(() => {
    async function getProductList() {
      const token = localStorage.getItem("token");
      const configs = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const data = {
        company: companyName,
      };
      try {
        const res = await axios.post(
          `${BACKEND_PROD_URL}/api/admin/getProductList`,
          data,
          configs
        );
        console.log(res);
        if (res.status === 200) setProductList(res.data);
        else {
          setOpen(true);
          setSnackbarMessage("Unknown error occured");
        }
      } catch (error) {
        console.log(error);
        if (error.response) {
          setOpen(true);
          setSnackbarMessage(error.response.data.message);
        } else {
          setOpen(true);
          setSnackbarMessage(error.message);
        }
      }
    }
    if (companyName.length !== 0) getProductList();
  }, [companyName]);

  React.useEffect(() => {
    async function getQRReport() {
      const token = localStorage.getItem("token");
      const configs = {
        headers: { Authorization: `Bearer ${token}` },
      };
      let res;
      try {
        if (privateQrReport) {
          console.log("calling private api")
          setPrivateAPiCalled(true);
          res = await axios.post(
            `${BACKEND_PROD_URL}/api/privateQrCode/getPrivateQRReport`,
            reqData,
            configs
          );
        } else {
          setPrivateAPiCalled(false);
          res = await axios.post(
            `${BACKEND_PROD_URL}/api/admin/getQRReport`,
            reqData,
            configs
          );
        }
        console.log(res.data);

        if (res.status === 200) {
          setRows(res.data);
          setOpen(true);
          setSnackbarMessage("Report generated successfully");
        } else {
          setOpen(true);
          setSnackbarMessage("Unknown error occured");
        }
      } catch (error) {
        console.log(error);
        if (error.response) {
          setOpen(true);
          setSnackbarMessage(error.response.data.message);
        } else {
          setOpen(true);
          setSnackbarMessage(error.message);
        }
      }
      setLoading(false);
    }
 
    async function getUserQRReport() {
      const token = localStorage.getItem("token");
      const configs = {
        headers: { Authorization: `Bearer ${token}` },
      };
      let res;
      try {
        if (privateQrReport) {
          console.log("calling private api")
          setPrivateAPiCalled(true);
          res = await axios.post(
            `${BACKEND_PROD_URL}/api/privateQrCode/getPrivateQRReport`,
            reqData,
            configs
          );
        } else {
          setPrivateAPiCalled(false);
          res = await axios.post(
            `${BACKEND_PROD_URL}/api/user/getUserQRReport`,
            reqData,
            configs
          );
        }
        console.log(res);
        if (res.status === 200) {
          setRows(res.data);
          setOpen(true);
          setSnackbarMessage("Report generated successfully");
        } else {
          setOpen(true);
          setSnackbarMessage("Unknown error occured");
        }
      } catch (error) {
        console.log(error);
        if (error.response) {
          setOpen(true);
          setSnackbarMessage(error.response.data.message);
        } else {
          setOpen(true);
          setSnackbarMessage(error.message);
        }
      }
      setLoading(false);
    }
    if (Object.keys(reqData).length !== 0 && currentUser.userrole === "ADMIN") {
      setLoading(true);
      getQRReport();
    }
    if (
      Object.keys(reqData).length !== 0 &&
      currentUser.userrole === "CLIENT"
    ) {
      setLoading(true);
      getUserQRReport();
    }
  }, [reqData]);

  React.useEffect(() => {
    async function getQRapiReport() {
      try {
        console.log(reqQRapiData);
        const res = await axios.get(
          `${BACKEND_PROD_URL}/api/admin/getQRapiDetails`,
          {
            params: reqQRapiData,
          }
        );
        console.log(res.data);
        if (res.status === 200) {
          console.log(res.data);
          setRows(res.data);
          setOpen(true);
          setSnackbarMessage("Report generated successfully");
        } else {
          setOpen(true);
          setSnackbarMessage("Unknown error occured");
        }
      } catch (error) {
        console.log(error);
        if (error.response) {
          setOpen(true);
          setSnackbarMessage(error.response.data);
        } else {
          setOpen(true);
          setSnackbarMessage(error.message);
        }
      }
      setLoading(false);
    }

    async function getUserQRapiReport() {
      try {
        console.log(reqQRapiData);
        const res = await axios.get(
          `${BACKEND_PROD_URL}/api/user/getQRapiDetails`,
          {
            params: reqQRapiData,
          }
        );
        console.log(res.data);
        if (res.status === 200) {
          console.log(res.data);
          setRows(res.data);
          setOpen(true);
          setSnackbarMessage("Report generated successfully");
        } else {
          setOpen(true);
          setSnackbarMessage("Unknown error occured");
        }
      } catch (error) {
        console.log(error);
        if (error.response) {
          setOpen(true);
          setSnackbarMessage(error.response.data);
        } else {
          setOpen(true);
          setSnackbarMessage(error.message);
        }
      }
      setLoading(false);
    }

    console.log("Inside QR API Filter", reqQRapiData);
    if (
      Object.keys(reqQRapiData).length !== 0 &&
      currentUser.userrole === "ADMIN"
    ) {
      setLoading(true);
      getQRapiReport();
    }
    if (
      Object.keys(reqQRapiData).length !== 0 &&
      currentUser.userrole === "CLIENT"
    ) {
      setLoading(true);
      getUserQRapiReport();
    }
  }, [reqQRapiData]);

  React.useEffect(() => {
    async function getQRapiReport() {
      try {
        console.log(reqQRapiData);
        const res = await axios.get(
          `${BACKEND_PROD_URL}/api/admin/getQRapiDetails`,
          {
            params: reqQRapiData,
          }
        );
        console.log(res.data);
        if (res.status === 200) {
          console.log(res.data);
          setRows(res.data);
          setOpen(true);
          setSnackbarMessage("Report generated successfully");
        } else {
          setOpen(true);
          setSnackbarMessage("Unknown error occured");
        }
      } catch (error) {
        console.log(error);
        if (error.response) {
          setOpen(true);
          setSnackbarMessage(error.response.data);
        } else {
          setOpen(true);
          setSnackbarMessage(error.message);
        }
      }
    }

    async function getUserQRapiReport() {
      try {
        console.log(reqQRapiData);
        const res = await axios.get(
          `${BACKEND_PROD_URL}/api/user/getQRapiDetails`,
          {
            params: reqQRapiData,
          }
        );
        console.log(res.data);
        if (res.status === 200) {
          console.log(res.data);
          setRows(res.data);
          setOpen(true);
          setSnackbarMessage("Report generated successfully");
        } else {
          setOpen(true);
          setSnackbarMessage("Unknown error occured");
        }
      } catch (error) {
        console.log(error);
        if (error.response) {
          setOpen(true);
          setSnackbarMessage(error.response.data);
        } else {
          setOpen(true);
          setSnackbarMessage(error.message);
        }
      }
    }

    const updateQRDetails = async () => {
      if (reqCallApiData) {
        try {
          setLoading(true);
          const response = await axios.get(
            `${BACKEND_PROD_URL}/api/qrcode/callApi`,
            {
              params: reqCallApiData,
            }
          );
          console.log(response.data);
          setRows([]);
          if (
            Object.keys(reqQRapiData).length !== 0 &&
            currentUser.userrole === "ADMIN"
          ) {
            getQRapiReport();
          }
          if (
            Object.keys(reqQRapiData).length !== 0 &&
            currentUser.userrole === "CLIENT"
          ) {
            getUserQRapiReport();
          }
          setLoading(false);
          setOpen(true);
          setSnackbarMessage("QR Code is Activated Successfully");
        } catch (error) {
          console.error(error);
          // Handle the error
          setLoading(false);
          setOpen(true);
          setSnackbarMessage("Error occured while calling KKISAN api");
        }
      }
    };

    if (Object.keys(reqCallApiData).length !== 0) {
      updateQRDetails();
    }
  }, [reqCallApiData]);

  const customFormatter = {
    // Convert large numbers to strings
    numberWithCommas: (value) => {
      if (typeof value === "string") {
        return value;
      } else if (typeof value === "number") {
        return value.toString();
      } else {
        return "";
      }
    },
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event, productalias) => {
    console.log(productalias);
    if (productalias !== null) {
      setProductAlias(productalias);
    }
    for (let i = 0; i < apiProductList.length; i++) {
      console.log(apiProductList[i]);
      if (productalias === apiProductList[i].productalias) {
        console.log(apiProductList[i].productcode);
        setProduct(apiProductList[i]);
        break;
      }
    }
    // setOpen(true)
    console.log(product.productcode);
  };

  const handleCompanyChange = (event, company) => {
    console.log(company);
    if (company !== null) {
      setCompanyName(company);
    }
  };

  React.useEffect(() => {
    async function getProductApiReport() {
      try {
        console.log(companyName, groupName);
        const res = await axios.get(
          `${BACKEND_PROD_URL}/api/qrcode/getProductApiDetails`,
          {
            params: {
              company: companyName,
              group: groupName,
            },
          }
        );

        setApiProductList(res.data);
        setProductAlias("");
      } catch (error) {
        console.log("Error while fetching QR codes:", error);
        setProductAlias("");
      }
    }
    getProductApiReport();
  }, [groupName]);

  const handleSubmit = () => {
    setReqData({});
    setRows([]);
    setPage(0);
    console.log(groupName, companyName, product);
    console.log("Inside handle submit");
    if (product === undefined) {
      setOpen(true);
      setSnackbarMessage("Select company/product");
    } else if (Object.keys(product).length === 0) {
      setOpen(true);
      setSnackbarMessage("Select company/product");
    } else if (fromDate === "" || fromDate === undefined) {
      setOpen(true);
      setSnackbarMessage("Please select from date");
    } else if (toDate === "" || toDate === undefined) {
      setOpen(true);
      setSnackbarMessage("Please select to date");
    } else if (productAlias === "" || productAlias === undefined) {
      setOpen(true);
      setSnackbarMessage("Please select product");
    } else if (
      product.productcode === undefined ||
      product.productcode === ""
    ) {
      console.log(product);
      setOpen(true);
      setSnackbarMessage("Incorrect details");
    } else {
      console.log(product.productcode);
      console.log(productAlias);
      const reqPayload = {
        group: groupName,
        fromDate: String(
          moment(fromDate).tz("Asia/Kolkata").format("YYYY-MM-DDTHH:mm:ssZ")
        ),
        toDate: String(
          moment(toDate).tz("Asia/Kolkata").format("YYYY-MM-DDTHH:mm:ssZ")
        ),
        productCode: product.productcode,
        productname: product.productname,
        productalias: productAlias,
        containerDetailsEnabled: containerEnabled,
        batchNo: batchNumber?.toUpperCase(),
      };
      setReqData(reqPayload);
    }
  };

  function LoadingAutocomplete({
    options,
    value,
    onChange,
    label,
    isLoading,
    fullWidth = true,
    size = "small",
    required = true,
  }) {
    return (
      <>
        {isLoading ? (
          <Skeleton variant="rounded" fullWidth height={40} />
        ) : (
          <FormControl fullWidth={fullWidth} required={required}>
            <Autocomplete
              id="combo-box-demo"
              size={size}
              options={options}
              value={value}
              onChange={onChange}
              renderInput={(params) => (
                <TextField required={required} {...params} label={label} />
              )}
            />
          </FormControl>
        )}
      </>
    );
  }

  return (
    <div>
      {pageLoading === true ? (
        <div style={{ marginTop: "10px", textAlign: "center" }}>
          <b>We are working on your request. Please be patient.</b>
          <Loading />{" "}
        </div>
      ) : (
        <div>
          {open === true ? (
            <Snackbar
              open={open}
              autoHideDuration={3000}
              onClose={handleClose}
              message={snackbarMessage}
              action={action}
            />
          ) : (
            <></>
          )}
          <UserGuard>
            <Topbar />
            <div>
              <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  style={{
                    width: "20%",
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                >
                  <AdminAuth>
                    <LoadingAutocomplete
                      options={companyList}
                      value={companyName}
                      onChange={handleCompanyChange}
                      label="Select Your Company"
                      isLoading={companyList.length === 0}
                    />
                  </AdminAuth>
                </div>
                <div style={{ width: "20%", marginRight: "10px" }}>
                  <LoadingAutocomplete
                    options={groupList.map((p) => p.name)}
                    value={groupName}
                    onChange={handleGroupChange}
                    label="Select Group"
                    isLoading={!companyName}
                    fullWidth={true}
                  />
                </div>
                <div style={{ width: "60%", marginRight: "10px" }}>
                  <LoadingAutocomplete
                    options={apiProductList.map((p) => p.productalias)}
                    value={productAlias}
                    onChange={handleChange}
                    label="Select Product"
                    isLoading={apiProductList.length === 0}
                    fullWidth={true}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "10px",
                }}
              >
                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    marginTop: "8px",
                  }}
                >
                  <TextField
                    id="outlined-basic"
                    label="Batch Number"
                    size="small"
                    variant="outlined"
                    value={batchNumber}
                    onInput={handleBatchNumberChange}
                  />
                </div>
                <div style={{ marginRight: "10px" }}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DemoContainer
                      style={{ maxWidth: "200px" }}
                      components={["DateTimePicker"]}
                    >
                      <DateTimePicker
                        label="from date"
                        value={fromDate}
                        onChange={(newValue) => setFromDate(newValue)}
                        ampm={false}
                        format="DD/MM/YYYY HH:mm"
                        sx={{
                          "& .MuiInputBase-root": {
                            width: "270px",
                            height: "40px",
                            fontSize: "14px",
                            overflow: "hidden",
                          },
                          "& .MuiSvgIcon-root": {
                            fontSize: "20px",
                          },
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                <div style={{ marginRight: "10px", overflow: "hidden" }}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DemoContainer components={["DateTimePicker"]}>
                      <DateTimePicker
                        label="to date"
                        value={toDate}
                        onChange={(newValue) => setToDate(newValue)}
                        ampm={false}
                        format="DD/MM/YYYY HH:mm"
                        sx={{
                          "& .MuiTextField-root": {
                            minWidth: "0px !important", // Override the min-width
                          },
                          "& .MuiInputBase-root": {
                            width: "270px",
                            height: "40px",
                            fontSize: "14px",
                            overflow: "hidden",
                          },
                          "& .MuiSvgIcon-root": {
                            fontSize: "20px",
                          },
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                <div className="flex justify-center items-center">
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={containerEnabled}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Container Enabled"
                    />
                  </FormControl>

                  <div style={{ margin: "10px" }}>
                    KKISAN API
                    <Switch
                      checked={privateQrReport}
                      onChange={generatePrivateQR}
                    />
                    Private
                    <Tooltip title="Disable the switch to avoid generating private QR codes">
                      <IconButton color="primary" aria-label="info">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>

                <div style={{ margin: "10px" }}>
                  <Button variant="contained" onClick={handleSubmit}>
                    Submit
                  </Button>
                </div>
              </div>
              <div>
                {rows.length === 0 ? (
                  <></>
                ) : (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        marginLeft: "10px",
                        marginRight: "10px",
                        marginTop: "8px",
                        width: "150px",
                      }}
                    >
                      <TextField
                        id="outlined-basic"
                        label="Start SN"
                        size="small"
                        variant="outlined"
                        value={startSN}
                        onInput={handleStartSNChange}
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: "10px",
                        marginRight: "10px",
                        marginTop: "8px",
                        width: "150px",
                      }}
                    >
                      <TextField
                        id="outlined-basic"
                        label="End SN"
                        size="small"
                        variant="outlined"
                        value={endSN}
                        onInput={handleEndSNChange}
                      />
                    </div>
                    <div style={{ margin: "10px", marginTop: "10px" }}>
                      <FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={printMRP}
                              onChange={handlePrintMRP}
                            />
                          }
                          label="Print MRP"
                        />
                      </FormControl>
                    </div>
                    {printMRP ? (
                      <div
                        style={{
                          marginLeft: "10px",
                          marginRight: "10px",
                          marginTop: "8px",
                          width: "150px",
                        }}
                      >
                        <TextField
                          id="outlined-basic"
                          label="Enter MRP"
                          size="small"
                          variant="outlined"
                          inputProps={{ maxLength: 13 }}
                          value={mrp}
                          onInput={handleMRPChange}
                        />
                      </div>
                    ) : (
                      <></>
                    )}

                    <Button
                      variant="contained"
                      onClick={handleClickPrintQRS}
                      style={{ margin: "10px" }}
                    >
                      Print-QRCodes
                    </Button>
                    {printqrsdialog === true ? (
                      <Dialog
                        open={printqrsdialog}
                        onClose={handleClosePrintQRS}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          {"Choose the qr code print format"}
                        </DialogTitle>
                        <DialogContent>
                          <Button
                            style={{ margin: "5px" }}
                            variant="contained"
                            onClick={async () => {
                              await generatePDF(
                                rows,
                                groupName,
                                companyName,
                                startSN,
                                endSN,
                                containerEnabled,
                                printMRP,
                                mrp,
                                setPageLoading,
                                setOpen,
                                setSnackbarMessage,
                                privateQrReport
                              );
                            }}
                          >
                            Print QR(2x1 inch)
                          </Button>
                          <Button
                            style={{ margin: "5px" }}
                            variant="contained"
                            onClick={async () => {
                              await generatev2PDF(
                                rows,
                                groupName,
                                companyName,
                                startSN,
                                endSN,
                                containerEnabled,
                                printMRP,
                                mrp,
                                setPageLoading,
                                setOpen,
                                setSnackbarMessage,
                                privateQrReport
                              );
                            }}
                          >
                            Print QR(A4 size)
                          </Button>
                          <Button
                            style={{ margin: "5px" }}
                            variant="contained"
                            onClick={async () => {
                              await generatev3PDF(
                                rows,
                                groupName,
                                companyName,
                                startSN,
                                endSN,
                                containerEnabled,
                                printMRP,
                                mrp,
                                setPageLoading,
                                setOpen,
                                setSnackbarMessage,
                                privateQrReport
                              );
                            }}
                          >
                            Print QR(1x1 inch)
                          </Button>
                        </DialogContent>
                      </Dialog>
                    ) : (
                      <></>
                    )}
                    <Button
                      variant="contained"
                      style={{
                        margin: "10px",
                        textDecoration: "none",
                        color: "#FFF",
                      }}
                    >
                      <CSVLink
                        data={rows}
                        filename={"QRDetails.csv"}
                        separator={","}
                        formatter={customFormatter}
                        style={{ textDecoration: "none", color: "#FFF" }}
                      >
                        Download Report
                      </CSVLink>
                    </Button>
                  </div>
                )}
              </div>
            </div>

            {editingRow && (
              <EditRowDialog
                open={EditRowDialogOpen}
                onClose={handleCloseEditRowDialog}
                row={editingRow}
                onSave={handleSaveEditRowDialog}
                displayMessage = {displayMessage}
              />
            )}
            {groupName ? (
              <div style={{ marginTop: "40px" }}>
                <TableContainer component={Paper}>
                  {loading === true ? (
                    <Loading />
                  ) : (
                    <Table
                      sx={{ minWidth: 500 }}
                      stickyHeader
                      aria-label="sticky table"
                    >
                      <TableHead>
                        <TableRow>
                          {privateApiCalled && (
                            <TableCell align="right" className="max-w-20">
                              Edit
                            </TableCell>
                          )}
                          {!containerEnabled && (
                            <TableCell
                              align={"right"}
                              className="pl-40"
                              style={{ minWidth: 100 }}
                            >
                              Product Name
                            </TableCell>
                          )}
                          <TableCell align={"right"} style={{ minWidth: 100 }}>
                            Product Alias
                          </TableCell>
                          {!containerEnabled && (
                            <TableCell
                              align={"right"}
                              style={{ minWidth: 100 }}
                            >
                              Serial Number
                            </TableCell>
                          )}
                          {(groupName === "SEEDS" ||
                            groupName === "FERTILIZER" ||
                            groupName === "PESTICIDES" ||
                            groupName === "CHEMICALS") && (
                            <TableCell
                              align={"right"}
                              style={{ minWidth: 100 }}
                            >
                              Batch Number
                            </TableCell>
                          )}
                          {(groupName === "SEEDS" ||
                            groupName === "FERTILIZER" ||
                            groupName === "PESTICIDES" ||
                            groupName === "TARPAULINS" ||
                            groupName === "CHEMICALS") && (
                            <TableCell
                              align={"right"}
                              style={{ minWidth: 100 }}
                            >
                              Manufacture Date
                            </TableCell>
                          )}
                          {(groupName === "SEEDS" ||
                            groupName === "FERTILIZER" ||
                            groupName === "PESTICIDES" ||
                            groupName === "TARPAULINS" ||
                            groupName === "CHEMICALS") && (
                            <TableCell
                              align={"right"}
                              style={{ minWidth: 100 }}
                            >
                              Expiry Date
                            </TableCell>
                          )}
                          {(groupName === "SEEDS" ||
                            groupName === "FERTILIZER" ||
                            groupName === "PESTICIDES" ||
                            groupName === "CHEMICALS" ||
                            groupName === "TARPAULINS") && (
                            <TableCell
                              align={"right"}
                              style={{ minWidth: 100 }}
                            >
                              Container QR Code
                            </TableCell>
                          )}
                          {(groupName === "SEEDS" ||
                            groupName === "FERTILIZER" ||
                            groupName === "PESTICIDES" ||
                            groupName === "CHEMICALS" ||
                            groupName === "TARPAULINS") && (
                            <TableCell
                              align={"right"}
                              style={{ minWidth: 100 }}
                            >
                              Container Range
                            </TableCell>
                          )}
                          {!containerEnabled &&
                            (groupName === "SEEDS" ||
                              groupName === "FERTILIZER" ||
                              groupName === "PESTICIDES" ||
                              groupName === "CHEMICALS" ||
                              groupName === "TARPAULINS" ||
                              groupName === "Farm Machinery Part-A" ||
                              groupName === "Farm Machinery Part-B" ||
                              groupName === "Farm Machinery Part-C") && (
                              <TableCell
                                align={"right"}
                                style={{ minWidth: 100 }}
                              >
                                QR Number
                              </TableCell>
                            )}
                          {(groupName === "Farm Machinery Part-A" ||
                            groupName === "Farm Machinery Part-B" ||
                            groupName === "Farm Machinery Part-C") && (
                            <>
                              <TableCell
                                align={"right"}
                                style={{ minWidth: 100 }}
                              >
                                Machine Serial Number
                              </TableCell>
                              <TableCell
                                align={"right"}
                                style={{ minWidth: 100 }}
                              >
                                Year of Manufacturing
                              </TableCell>
                              <TableCell
                                align={"right"}
                                style={{ minWidth: 100 }}
                              >
                                MRP
                              </TableCell>
                            </>
                          )}
                          {groupName === "Farm Machinery Part-B" && (
                            <>
                              <TableCell
                                align={"right"}
                                style={{ minWidth: 100 }}
                              >
                                Engine Number
                              </TableCell>
                              <TableCell
                                align={"right"}
                                style={{ minWidth: 100 }}
                              >
                                Chasis Number
                              </TableCell>
                            </>
                          )}
                          {groupName === "Farm Machinery Part-C" && (
                            <>
                              <TableCell
                                align={"right"}
                                style={{ minWidth: 100 }}
                              >
                                Engine Number
                              </TableCell>
                              <TableCell
                                align={"right"}
                                style={{ minWidth: 100 }}
                              >
                                Pump Number
                              </TableCell>
                              <TableCell
                                align={"right"}
                                style={{ minWidth: 100 }}
                              >
                                Engine IS Licence Validity
                              </TableCell>
                              <TableCell
                                align={"right"}
                                style={{ minWidth: 100 }}
                              >
                                Pump IS Licence Validity
                              </TableCell>
                            </>
                          )}
                          {groupName === "MICRO IRRIGATION" && (
                            <>
                              {!containerEnabled && (
                                <TableCell
                                  align={"right"}
                                  style={{ minWidth: 100 }}
                                >
                                  QR Number
                                </TableCell>
                              )}
                              <TableCell
                                align={"right"}
                                style={{ minWidth: 100 }}
                              >
                                Container QR Code
                              </TableCell>
                              <TableCell
                                align={"right"}
                                style={{ minWidth: 100 }}
                              >
                                Container Range
                              </TableCell>
                              <TableCell
                                align={"right"}
                                style={{ minWidth: 100 }}
                              >
                                Batch Number
                              </TableCell>
                            </>
                          )}
                          <TableCell align={"right"} style={{ minWidth: 100 }}>
                            Created Date
                          </TableCell>
                          {filter === "Date Filter" ? (
                            <TableCell
                              align={"right"}
                              style={{ minWidth: 100 }}
                            >
                              Active QR
                            </TableCell>
                          ) : (
                            <></>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(rowsPerPage > 0
                          ? rows.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                          : rows
                        ).map((row, i) => (
                          <TableRow key={i}>
                            {privateApiCalled && (
                              <TableCell
                                align="right"
                                style={{ minWidth: 100 }}
                              >
                                <div
                                  className=" hover:cursor-pointer"
                                  onClick={() => handleEditRowDialog(row)}
                                >
                                  <ModeEditIcon />
                                </div>
                              </TableCell>
                            )}
                            {!containerEnabled && (
                              <TableCell style={{ width: 160 }} align="center">
                                {row.ProductName ? String(row.ProductName) : ""}
                              </TableCell>
                            )}
                            <TableCell style={{ width: 160 }} align="right">
                              {row.ProductAlias ? String(row.ProductAlias) : ""}
                            </TableCell>
                            {!containerEnabled && (
                              <TableCell style={{ width: 160 }} align="right">
                                {row.SerialNumber ? row.SerialNumber : ""}
                              </TableCell>
                            )}
                            {(groupName === "SEEDS" ||
                              groupName === "FERTILIZER" ||
                              groupName === "PESTICIDES" ||
                              groupName === "CHEMICALS") && (
                              <TableCell
                                align={"right"}
                                style={{ minWidth: 100 }}
                              >
                                {" "}
                                {row.BatchNumber ? String(row.BatchNumber) : ""}
                              </TableCell>
                            )}
                            {(groupName === "SEEDS" ||
                              groupName === "FERTILIZER" ||
                              groupName === "PESTICIDES" ||
                              groupName === "TARPAULINS" ||
                              groupName === "CHEMICALS") && (
                              <TableCell
                                align={"right"}
                                style={{ minWidth: 100 }}
                              >
                                {row.ManufactureDate
                                  ? String(row.ManufactureDate)
                                  : ""}
                              </TableCell>
                            )}
                            {(groupName === "SEEDS" ||
                              groupName === "FERTILIZER" ||
                              groupName === "PESTICIDES" ||
                              groupName === "TARPAULINS" ||
                              groupName === "CHEMICALS") && (
                              <TableCell
                                align={"right"}
                                style={{ minWidth: 100 }}
                              >
                                {row.ExpiryDate ? String(row.ExpiryDate) : ""}
                              </TableCell>
                            )}
                            {(groupName === "SEEDS" ||
                              groupName === "FERTILIZER" ||
                              groupName === "PESTICIDES" ||
                              groupName === "CHEMICALS" ||
                              groupName === "TARPAULINS") && (
                              <TableCell
                                align={"right"}
                                style={{ minWidth: 100 }}
                              >
                                {row.ContainerQRCode
                                  ? String(row.ContainerQRCode).slice(3)
                                  : ""}
                              </TableCell>
                            )}
                            {(groupName === "SEEDS" ||
                              groupName === "FERTILIZER" ||
                              groupName === "PESTICIDES" ||
                              groupName === "CHEMICALS" ||
                              groupName === "TARPAULINS") && (
                              <TableCell
                                align={"right"}
                                style={{ minWidth: 100 }}
                              >
                                {row.ContainerRange
                                  ? String(row.ContainerRange)
                                  : ""}
                              </TableCell>
                            )}
                            {!containerEnabled &&
                              (groupName === "SEEDS" ||
                                groupName === "FERTILIZER" ||
                                groupName === "PESTICIDES" ||
                                groupName === "CHEMICALS" ||
                                groupName === "TARPAULINS" ||
                                groupName === "Farm Machinery Part-A" ||
                                groupName === "Farm Machinery Part-B" ||
                                groupName === "Farm Machinery Part-C") && (
                                <TableCell
                                  align={"right"}
                                  style={{ minWidth: 100 }}
                                >
                                  {row.QRNumber
                                    ? String(row.QRNumber).slice(3)
                                    : ""}
                                </TableCell>
                              )}
                            {(groupName === "Farm Machinery Part-A" ||
                              groupName === "Farm Machinery Part-B" ||
                              groupName === "Farm Machinery Part-C") && (
                              <>
                                <TableCell
                                  align={"right"}
                                  style={{ minWidth: 100 }}
                                >
                                  {row.MachineSerialNumber
                                    ? String(row.MachineSerialNumber)
                                    : ""}
                                </TableCell>
                                <TableCell
                                  align={"right"}
                                  style={{ minWidth: 100 }}
                                >
                                  {row.YearOfManufacture
                                    ? String(row.YearOfManufacture)
                                    : ""}
                                </TableCell>
                                <TableCell
                                  align={"right"}
                                  style={{ minWidth: 100 }}
                                >
                                  {row.MRP ? String(row.MRP) : ""}
                                </TableCell>
                              </>
                            )}
                            {groupName === "Farm Machinery Part-B" && (
                              <>
                                <TableCell
                                  align={"right"}
                                  style={{ minWidth: 100 }}
                                >
                                  {row.EngineNumber
                                    ? String(row.EngineNumber)
                                    : ""}
                                </TableCell>
                                <TableCell
                                  align={"right"}
                                  style={{ minWidth: 100 }}
                                >
                                  {row.ChasisNumber
                                    ? String(row.ChasisNumber)
                                    : ""}
                                </TableCell>
                              </>
                            )}
                            {groupName === "Farm Machinery Part-C" && (
                              <>
                                <TableCell
                                  align={"right"}
                                  style={{ minWidth: 100 }}
                                >
                                  {row.EngineNumber
                                    ? String(row.EngineNumber)
                                    : ""}
                                </TableCell>
                                <TableCell
                                  align={"right"}
                                  style={{ minWidth: 100 }}
                                >
                                  {row.PumpNumber ? String(row.PumpNumber) : ""}
                                </TableCell>
                                <TableCell
                                  align={"right"}
                                  style={{ minWidth: 100 }}
                                >
                                  {row.EngineIsLicenceValidity
                                    ? String(row.EngineIsLicenceValidity)
                                    : ""}
                                </TableCell>
                                <TableCell
                                  align={"right"}
                                  style={{ minWidth: 100 }}
                                >
                                  {row.PumpIsLicenceValidity
                                    ? String(row.PumpIsLicenceValidity)
                                    : ""}
                                </TableCell>
                              </>
                            )}
                            {groupName === "MICRO IRRIGATION" && (
                              <>
                                {!containerEnabled && (
                                  <TableCell
                                    align={"right"}
                                    style={{ minWidth: 100 }}
                                  >
                                    {row.QRNumber
                                      ? String(row.QRNumber).slice(3)
                                      : ""}
                                  </TableCell>
                                )}
                                <TableCell
                                  align={"right"}
                                  style={{ minWidth: 100 }}
                                >
                                  {" "}
                                  {row.ContainerQRCode
                                    ? String(row.ContainerQRCode).slice(3)
                                    : ""}
                                </TableCell>
                                <TableCell
                                  align={"right"}
                                  style={{ minWidth: 100 }}
                                >
                                  {console.log(
                                    "row container range" + row.ContainerRange
                                  )}
                                  {row.ContainerRange
                                    ? String(row.ContainerRange)
                                    : ""}
                                </TableCell>
                                <TableCell
                                  align={"right"}
                                  style={{ minWidth: 100 }}
                                >
                                  {row.BatchNumber
                                    ? String(row.BatchNumber)
                                    : ""}
                                </TableCell>
                              </>
                            )}
                            <TableCell style={{ width: 160 }} align="right">
                              {row.createdat
                                ? String(row.createdat).slice(3)
                                : ""}
                            </TableCell>
                            {filter === "Date Filter" ? (
                              <TableCell
                                align={"right"}
                                style={{ minWidth: 100 }}
                              >
                                {String(row.isCallExtApi) === "true"
                                  ? "Active"
                                  : "InActive"}
                              </TableCell>
                            ) : (
                              <></>
                            )}
                            {filter === "QR api Filter" ? (
                              <>
                                {" "}
                                <TableCell
                                  align={"right"}
                                  style={{ minWidth: 100 }}
                                >
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => handleEdit(row)}
                                    disabled={
                                      groupName !== "Farm Machinery Part-A" &&
                                      groupName !== "Farm Machinery Part-B" &&
                                      groupName !== "Farm Machinery Part-C"
                                    }
                                  >
                                    Edit
                                  </Button>
                                </TableCell>
                                <TableCell
                                  align={"right"}
                                  style={{ minWidth: 100 }}
                                >
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => handleActivateQRCode(row)}
                                    disabled={
                                      groupName !== "Farm Machinery Part-A" &&
                                      groupName !== "Farm Machinery Part-B" &&
                                      groupName !== "Farm Machinery Part-C"
                                    }
                                  >
                                    Activate QR Code
                                  </Button>
                                </TableCell>{" "}
                              </>
                            ) : (
                              <></>
                            )}
                          </TableRow>
                        ))}

                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[
                              5,
                              10,
                              25,
                              { label: "All", value: -1 },
                            ]}
                            colSpan={3}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                              inputProps: {
                                "aria-label": "rows per page",
                              },
                              native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  )}
                </TableContainer>
              </div>
            ) : (
              <></>
            )}

            <div>
              {/* Dialog form for Farm Machinery Part-A */}
              {/* <Dialog
                open={openEditForm}
                onClose={() => setOpenEditForm(false)}
              >
                <DialogTitle>{groupName} Edit Form</DialogTitle>
                <Box component="form" onSubmit={handleSaveForm} sx={{ mt: 1 }}>
                  <DialogContent>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item xs={6}>
                        <Item>
                          <b>QR Number</b>
                        </Item>
                      </Grid>
                      <Grid item xs={6}>
                        <Item>
                          <b>
                            {editFormValues.QRNumber
                              ? editFormValues.QRNumber.slice(3)
                              : ""}
                          </b>
                        </Item>
                      </Grid>
                    </Grid>
                    {groupName === "Farm Machinery Part-A" && (
                      <div>
                        <TextField
                          label="Machine Serial Number"
                          name="machineSerialNumber"
                          id="machineSerialNumber"
                          fullWidth
                          margin="normal"
                          defaultValue={editFormValues.MachineSerialNumber}
                        />
                        <TextField
                          label="Year of Manufacturing"
                          name="yearOfManufacturing"
                          id="yearOfManufacturing"
                          fullWidth
                          margin="normal"
                          defaultValue={editFormValues.YearOfManufacture}
                        />
                        <TextField
                          label="MRP"
                          name="mrp"
                          id="mrp"
                          fullWidth
                          margin="normal"
                          defaultValue={editFormValues.MRP}
                        />
                      </div>
                    )}
                    {groupName === "Farm Machinery Part-B" && (
                      <div>
                        <TextField
                          label="Machine Serial Number"
                          name="machineSerialNumber"
                          id="machineSerialNumber"
                          fullWidth
                          margin="normal"
                          defaultValue={editFormValues.MachineSerialNumber}
                        />
                        <TextField
                          label="Engine Number"
                          name="engineNumber"
                          id="engineNumber"
                          fullWidth
                          margin="normal"
                          defaultValue={editFormValues.EngineNumber}
                        />
                        <TextField
                          label="Chasis Number"
                          name="chasisNumber"
                          id="chasisNumber"
                          fullWidth
                          margin="normal"
                          defaultValue={editFormValues.ChasisNumber}
                        />
                        <TextField
                          label="Year of Manufacturing"
                          name="yearOfManufacturing"
                          id="yearOfManufacturing"
                          fullWidth
                          margin="normal"
                          defaultValue={editFormValues.YearOfManufacture}
                        />
                        <TextField
                          label="MRP"
                          name="mrp"
                          id="mrp"
                          fullWidth
                          margin="normal"
                          defaultValue={editFormValues.MRP}
                        />
                      </div>
                    )}
                    {groupName === "Farm Machinery Part-C" && (
                      <div>
                        <TextField
                          label="Machine Serial Number"
                          name="machineSerialNumber"
                          id="machineSerialNumber"
                          fullWidth
                          margin="normal"
                          defaultValue={editFormValues.MachineSerialNumber}
                        />
                        <TextField
                          label="Engine Number"
                          name="engineNumber"
                          id="engineNumber"
                          fullWidth
                          margin="normal"
                          defaultValue={editFormValues.EngineNumber}
                        />
                        <TextField
                          label="Pump Number"
                          name="pumpNumber"
                          id="pumpNumber"
                          fullWidth
                          margin="normal"
                          defaultValue={editFormValues.PumpNumber}
                        />
                        <TextField
                          label="Engine IS Licence Validity"
                          name="engineIsLicenceValidity"
                          id="engineIsLicenceValidity"
                          fullWidth
                          margin="normal"
                          defaultValue={editFormValues.EngineIsLicenceValidity}
                        />
                        <TextField
                          label="Pump IS Licence Validity"
                          name="pumpIsLicenceValidity"
                          id="pumpIsLicenceValidity"
                          fullWidth
                          margin="normal"
                          defaultValue={editFormValues.PumpIsLicenceValidity}
                        />
                        <TextField
                          label="Year of Manufacturing"
                          name="yearOfManufacturing"
                          id="yearOfManufacturing"
                          fullWidth
                          margin="normal"
                          defaultValue={editFormValues.YearOfManufacture}
                        />
                        <TextField
                          label="MRP"
                          name="mrp"
                          id="mrp"
                          fullWidth
                          margin="normal"
                          defaultValue={editFormValues.MRP}
                        />
                      </div>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setOpenEditForm(false)}
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button type="submit" color="primary">
                      Save
                    </Button>
                  </DialogActions>
                </Box>
              </Dialog> */}
            </div>
            <div>
              {/* <Dialog open={openPreview} onClose={handleClosePreview}>
                <DialogTitle>Activate QR Code</DialogTitle>
                <DialogContent dividers>
                  {selectedRow && (
                    <Grid container columnSpacing={{ xs: 3, sm: 8, md: 8 }}>
                      <Grid item xs={6}>
                        <Grid container direction="column">
                          <Grid item>
                            <Item
                              style={{
                                width: "200px",
                                height: "25px",
                                margin: "2px",
                              }}
                            >
                              Product Name:
                            </Item>
                          </Grid>
                          <Grid item xs={6}>
                            <Item
                              style={{
                                width: "200px",
                                height: "25px",
                                margin: "2px",
                              }}
                            >
                              Product Alias:
                            </Item>
                          </Grid>
                          {!containerEnabled && (
                            <Grid item xs={6}>
                              <Item
                                style={{
                                  width: "200px",
                                  height: "25px",
                                  margin: "2px",
                                }}
                              >
                                Serial Number:
                              </Item>
                            </Grid>
                          )}
                          {(groupName === "SEEDS" ||
                            groupName === "FERTILIZER" ||
                            groupName === "PESTICIDES" ||
                            groupName === "CHEMICALS") && (
                            <>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  Batch Number:
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  Manufacture Date:
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  Expiry Date:
                                </Item>
                              </Grid>
                            </>
                          )}
                          {(groupName === "SEEDS" ||
                            groupName === "FERTILIZER" ||
                            groupName === "PESTICIDES" ||
                            groupName === "CHEMICALS" ||
                            groupName === "TARPAULINS") && (
                            <>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  Container QR Code:
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  Container Range:
                                </Item>
                              </Grid>
                            </>
                          )}
                          {(groupName === "SEEDS" ||
                            groupName === "FERTILIZER" ||
                            groupName === "PESTICIDES" ||
                            groupName === "CHEMICALS" ||
                            groupName === "TARPAULINS" ||
                            groupName === "Farm Machinery Part-A" ||
                            groupName === "Farm Machinery Part-B" ||
                            groupName === "Farm Machinery Part-C") && (
                            <Grid item>
                              <Item
                                style={{
                                  width: "200px",
                                  height: "25px",
                                  margin: "2px",
                                }}
                              >
                                QR Number:
                              </Item>
                            </Grid>
                          )}
                          {(groupName === "Farm Machinery Part-A" ||
                            groupName === "Farm Machinery Part-B" ||
                            groupName === "Farm Machinery Part-C") && (
                            <>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  Machine Serial Number:
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  Year of Manufacturing:
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  MRP:
                                </Item>
                              </Grid>
                            </>
                          )}
                          {groupName === "Farm Machinery Part-B" && (
                            <>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  Engine Number:
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  Chasis Number:
                                </Item>
                              </Grid>
                            </>
                          )}
                          {groupName === "Farm Machinery Part-C" && (
                            <>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  Engine Number:
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  Pump Number:
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  Engine IS Licence Validity:
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  Pump IS Licence Validity:
                                </Item>
                              </Grid>
                            </>
                          )}
                          {groupName === "TARPAULINS" && (
                            <>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  MRP:
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  Batch Number:
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  Manf Date and Month:
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  BIS Licence Validity:
                                </Item>
                              </Grid>
                            </>
                          )}
                          {groupName === "MICRO IRRIGATION" && (
                            <>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  QR Number:
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  Container QR Code:
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  Container Range:
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  Batch Number:
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  BIS Number and Validity:
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  CM/L No.:
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  Year of Manufacturing:
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  MRP:
                                </Item>
                              </Grid>
                            </>
                          )}
                          <Grid item>
                            <Item
                              style={{
                                width: "200px",
                                height: "25px",
                                margin: "2px",
                              }}
                            >
                              Created Date:
                            </Item>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container direction="column">
                          <Grid item>
                            {" "}
                            <Item
                              style={{
                                width: "200px",
                                height: "25px",
                                margin: "2px",
                              }}
                            >
                              {selectedRow.ProductName}
                            </Item>
                          </Grid>
                          <Grid item>
                            <Item
                              style={{
                                width: "200px",
                                height: "25px",
                                margin: "2px",
                              }}
                            >
                              {selectedRow.ProductAlias}
                            </Item>
                          </Grid>
                          {!containerEnabled && (
                            <Grid item>
                              <Item
                                style={{
                                  width: "200px",
                                  height: "25px",
                                  margin: "2px",
                                }}
                              >
                                {selectedRow.SerialNumber}
                              </Item>
                            </Grid>
                          )}
                          {(groupName === "SEEDS" ||
                            groupName === "FERTILIZER" ||
                            groupName === "PESTICIDES" ||
                            groupName === "CHEMICALS") && (
                            <>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  {selectedRow.BatchNumber}
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  {selectedRow.ManufactureDate}
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  {selectedRow.ExpiryDate}
                                </Item>
                              </Grid>
                            </>
                          )}
                          {(groupName === "SEEDS" ||
                            groupName === "FERTILIZER" ||
                            groupName === "PESTICIDES" ||
                            groupName === "CHEMICALS" ||
                            groupName === "TARPAULINS") && (
                            <>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  {selectedRow.ContainerQRCode.slice(3)}
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  {selectedRow.ContainerRange}
                                </Item>
                              </Grid>
                            </>
                          )}
                          {(groupName === "SEEDS" ||
                            groupName === "FERTILIZER" ||
                            groupName === "PESTICIDES" ||
                            groupName === "CHEMICALS" ||
                            groupName === "TARPAULINS" ||
                            groupName === "Farm Machinery Part-A" ||
                            groupName === "Farm Machinery Part-B" ||
                            groupName === "Farm Machinery Part-C") && (
                            <Grid item>
                              <Item
                                style={{
                                  width: "200px",
                                  height: "25px",
                                  margin: "2px",
                                }}
                              >
                                {selectedRow.QRNumber.slice(3)}
                              </Item>
                            </Grid>
                          )}
                          {(groupName === "Farm Machinery Part-A" ||
                            groupName === "Farm Machinery Part-B" ||
                            groupName === "Farm Machinery Part-C") && (
                            <>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  {selectedRow.MachineSerialNumber}
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  {selectedRow.YearOfManufacture}
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  {selectedRow.MRP}
                                </Item>
                              </Grid>
                            </>
                          )}
                          {groupName === "Farm Machinery Part-B" && (
                            <>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  {selectedRow.EngineNumber}
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  {selectedRow.ChasisNumber}
                                </Item>
                              </Grid>
                            </>
                          )}
                          {groupName === "Farm Machinery Part-C" && (
                            <>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  {selectedRow.EngineNumber}
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  {selectedRow.PumpNumber}
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  {selectedRow.EngineIsLicenceValidity}
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  {selectedRow.PumpIsLicenceValidity}
                                </Item>
                              </Grid>
                            </>
                          )}
                          {groupName === "TARPAULINS" && (
                            <>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  {selectedRow.mrp}
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  {selectedRow.BatchNumber}
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  {selectedRow.MfgMonthAndYear}
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  {selectedRow.BISLicenceValidity}
                                </Item>
                              </Grid>
                            </>
                          )}
                          {groupName === "MICRO IRRIGATION" && (
                            <>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  {selectedRow.QRNumber.slice(3)}
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  {selectedRow.ContainerQRCode.slice(3)}
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  {selectedRow.ContainerRange}
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  {selectedRow.BatchNumber}
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  {selectedRow.BISNumberAndValidity}
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  {selectedRow.CMLNo}
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  {selectedRow.YearOfManufacture}
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item
                                  style={{
                                    width: "200px",
                                    height: "25px",
                                    margin: "2px",
                                  }}
                                >
                                  {selectedRow.MRP}
                                </Item>
                              </Grid>
                            </>
                          )}
                          <Grid item>
                            <Item
                              style={{
                                width: "200px",
                                height: "25px",
                                margin: "2px",
                              }}
                            >
                              {selectedRow.createdat.slice(3)}
                            </Item>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClosePreview}>Cancel</Button>
                  <Button
                    color="primary"
                    onClick={() => handleCallAPI(selectedRow)}
                  >
                    Activate
                  </Button>
                </DialogActions>
              </Dialog> */}
            </div>
          </UserGuard>
        </div>
      )}
    </div>
  );
};

export default Report;
