import React from "react";
import Image from "../assets/person.png";
import { motion } from "framer-motion";
import AnimatedNumbers from "react-animated-numbers";
import {
  imageVariant,
  textVariant,
  buttonVariant,
} from "../components/Animation";

const WhyUsSection = () => {
  return (
    <section id="why-we" className="bg-[#f9f9f9]  md:py-16">
      {/* Divider */}
      <div className="bg-[#e4e4e4] h-[0.5px] w-full" />

      {/* Main Container */}
      <div className="flex flex-col lg:flex-row justify-around items-center md:px-20 px-5 gap-12 mt-12">
        {/* Left Content */}
        <motion.div
          variants={textVariant}
          initial="hidden"
          animate="visible"
          className="flex flex-col w-full lg:w-[49rem] text-center lg:text-left shadow-2xl rounded-2xl bg-white p-8"
        >
          <h2 className="text-4xl font-extrabold mb-6 text-center">About Us</h2>
          <p className="text-lg leading-relaxed mb-6">
            At Maili Enterprises, our goal is to make your QR code generation
            process simple and hassle-free. With a happy user base of over 50
            businesses, we listen to your needs and continuously upgrade our
            platform to give you the best experience.
          </p>

          {/* Stats Section */}
          <div className="flex flex-col md:flex-row gap-8 justify-center">
            {/* Card 1 */}
            <div className="bg-white shadow-lg rounded-lg py-6 px-4 flex-1">
              <div className="text-4xl font-bold text-green-600 flex items-center justify-center">
                <AnimatedNumbers
                  includeComma
                  transitions={(index) => ({
                    type: "spring",
                    duration: index + 0.3,
                  })}
                  animateToNumber={100}
                  className="text-green-600"
                  fontStyle={{
                    fontSize: 40,
                  }}
                />
                +
              </div>
              <p className="mt-2 text-gray-700 text-center">
                users revolutionizing agriculture with our QR code solutions
              </p>
            </div>

            {/* Card 2 */}
            <div className="bg-white shadow-lg rounded-lg py-6 px-4 flex-1">
              <div className="text-4xl font-bold text-green-600 flex items-center justify-center">
                <AnimatedNumbers
                  includeComma
                  transitions={(index) => ({
                    type: "spring",
                    duration: index + 0.3,
                  })}
                  animateToNumber={101000}
                  className="text-green-600"
                  fontStyle={{
                    fontSize: 40,
                  }}
                />
                +
              </div>
              <p className="mt-2 text-gray-700 text-center">
                QR codes created—simplifying agri-product tracking like never
                before!
              </p>
            </div>
          </div>

          {/* Call-to-Action */}
          <p className="text-lg mt-8 leading-relaxed">
            GET QR CODES is more than software—it’s a reliable partner to help
            your business grow with ease, transparency, and compliance. We are
            committed to providing solutions that save time, reduce effort, and
            support your success.
          </p>

          <div className="flex justify-center lg:justify-start mt-10">
            <a href="/login">
              <motion.button
                type="button"
                variants={buttonVariant}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-gradient-to-r text-white text-xl from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg px-6 py-3 shadow-md"
              >
                Contact Us
              </motion.button>
            </a>
          </div>
        </motion.div>

        {/* Right Image */}
        <motion.div
          className="relative"
          variants={imageVariant}
          initial="hidden"
          animate="visible"
        >
          <img
            src={Image}
            alt="Person using our services"
            className="h-[320px] lg:h-[500px] rounded-xl "
          />
          <div className="absolute bottom-0 left-0 w-full h-20 bg-gradient-to-t from-[#ffffff] to-transparent opacity-70"></div>
        </motion.div>
      </div>
    </section>
  );
};

export default WhyUsSection;
