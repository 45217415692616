import React, { useState } from "react";
import Image from "../assets/person-2.png";
import { motion } from "framer-motion";
import axios from "axios";
import { BACKEND_PROD_URL } from "../config";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import {
  imageVariant,
  textVariant,
  buttonVariant,
} from "../components/Animation";

const HeroSection = () => {
  const [email, setEmail] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [message, setMessage] = useState("Message successfully sent");
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    setShowSnackbar(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setMessage("Please enter a valid email address");
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 4000);
      setLoading(false);
      return;
    }

    try {
      await axios.post(`${BACKEND_PROD_URL}/api/admin/contactUs`, { email });
      setMessage("Message successfully sent");
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 4000);
      setEmail(""); // Clear input after successful submit
    } catch (error) {
      console.error(error);
      setMessage("Something went wrong. Please try again.");
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 4000);
    }

    setLoading(false);
  };

  return (
    <div className="flex flex-col lg:flex-row justify-around items-center p-4 lg:p-0 border-b border-[#e4e4e4]">
      {/* Animated Image */}
      <motion.div
        className="mb-8 lg:mb-0 relative"
        variants={imageVariant}
        initial="hidden"
        animate="visible"
      >
        <img
          loading="lazy"
          src={Image}
          className="h-[320px] lg:h-[620px]"
          alt="Hero"
        />
        <div className="absolute bottom-0 left-0 w-full h-20 bg-gradient-to-t from-[#ffffff] to-transparent opacity-60"></div>
      </motion.div>

      {/* Animated Text Section */}
      <motion.div
        className="flex flex-col lg:flex-wrap w-full lg:w-[49rem] text-center"
        variants={textVariant}
        initial="hidden"
        animate="visible"
      >
        <p className="text-3xl lg:text-5xl font-bold mb-4 lg:mb-0">
          Looking for an easy way to manage and track your agri-products?
        </p>
        <p className="text-lg lg:text-md mb-4 pt-5 lg:mb-0 md:pt-10">
          With GetQRCodes, create QR codes effortlessly to manage inventory and
          ensure traceability from farm to market. Simplify your process today!
        </p>

        {/* Input and Button Section */}
        <div className="flex justify-center flex-col md:flex-row gap-5 pt-10 items-center">
          <motion.div
            variants={textVariant}
            initial="hidden"
            animate="visible"
            transition={{ delay: 0.5 }}
          >
            <input
              type="email"
              autoComplete="email"
              className="border rounded-lg p-2 lg:p-3 text-center border-black text-lg lg:text-xl mb-4 lg:mb-0"
              placeholder="Enter Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </motion.div>
          <motion.div
            variants={buttonVariant}
            whileHover="hover"
            transition={{ delay: 0.2 }}
          >
            <button
              type="submit"
              className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800 text-xl  rounded-lg px-5 py-3 text-center"
              onClick={handleSubmit}
              disabled={loading}
            >
              <div className="flex items-center justify-center gap-3">
                Try it in action
                {loading && (
                  <CircularProgress size={24} className="text-white" />
                )}
              </div>
            </button>
          </motion.div>
        </div>
      </motion.div>

      {/* Snackbar for status messages */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
        message={message}
        action={action}
      />
    </div>
  );
};

export default HeroSection;
