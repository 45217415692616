import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from '@mui/material';
import { deleteNotification } from '../apis/userApis';
import useAuth from '../hooks/useAuth';

const NotificationDialog = ({ open, handleClose, notifications, email }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Close the dialog automatically if there are no notifications or if all are read
  useEffect(() => {
    if (notifications.length === 0 || currentIndex >= notifications.length) {
      handleClose();
    }
  }, [currentIndex, notifications.length, handleClose]);

  const handleMarkAsRead = async (notificationId) => {
    try {
      // API call to mark notification as read
      console.log("inside handleMark as read")
      const res = await deleteNotification({email: email, notificationId: notificationId});      
      // Move to the next notification
      setCurrentIndex(currentIndex + 1);
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  return (
    <Dialog open={open && notifications.length > 0} onClose={handleClose} maxWidth="sm" fullWidth>
      {notifications.length > 0 && currentIndex < notifications.length ? (
        <>
          {/* Display the number of notifications */}
          <DialogTitle>
            {`Notification ${currentIndex + 1} of ${notifications.length}`}
          </DialogTitle>

          {/* Display the current notification */}
          <DialogContent>
            <Typography variant="h6">{notifications[currentIndex].title}</Typography>
            <Typography style={{ whiteSpace: 'pre-line' }}>{notifications[currentIndex].message}</Typography>
          </DialogContent>

          <DialogActions>
            {/* Button to mark the current notification as read */}
            <Button
              onClick={() =>
                handleMarkAsRead(notifications[currentIndex]._id)
              }
              color="primary"
              variant="contained"
            >
              Mark as Read
            </Button>

            {/* Optional: Close the dialog */}
            <Button onClick={handleClose} color="secondary">
              Close
            </Button>
          </DialogActions>
        </>
      ) : null}
    </Dialog>
  );
};

export default NotificationDialog;
