export const imageVariant = {
  hidden: { x: "-100%", opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
    transition: { type: "spring", stiffness: 80, damping: 15 },
  },
};

export const textVariant = {
  hidden: { opacity: 0, y: 30 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { delay: 0.3, duration: 0.6, ease: "easeInOut" },
  },
};

export const buttonVariant = {
  hover: { scale: 1.03, transition: { duration: 0.3 } },
  exit: { scale: 0 },
};
