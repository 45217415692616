import axios from "axios";
import { BACKEND_PROD_URL } from "../config";
export const getUser = async (token) => {
  const getUserConfig = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const res = await axios.get(`${BACKEND_PROD_URL}/api/user/`, getUserConfig);
  return res;
};

export const deleteNotification = async (userData) => {
  console.log("calling delete API ", userData);
  const res = await axios.post(
    `${BACKEND_PROD_URL}/api/user/deleteNotification`,
    userData
  );
  console.log(res.data);
  return res.data;
};
