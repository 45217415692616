import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.css";
import { AuthProvider } from "./components/AuthContext";

const container = document.getElementById("root");
const root = createRoot(container);

if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
  console.debug = () => {};
  console.info = () => {};
  console.warn = () => {};
}

root.render(
  <AuthProvider>
    <App />
  </AuthProvider>
);
