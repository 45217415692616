import React, { useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { motion, AnimatePresence } from "framer-motion";
import logo from "../assets/logo.png";

const drawerWidth = 240;

export const navItems = [
  {
    name: "About us",
    src: "#why-we",
  },
  {
    name: "Product",
    dropdown: [
      { name: "Key Features", src: "#perfect-solution" },
      { name: "Glimpses", src: "#features" },
      { name: "Other Services", src: "#services" },
    ],
  },
  {
    name: "Pricing",
    src: "#pricing",
  },
  {
    name: "Contact Us",
    src: "#contact-us",
  },
  {
    name: "Login",
    src: "/login",
  },
];

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleDropdownToggle = () => {
    setDropdownOpen((prev) => !prev);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <img
        src={logo}
        loading="lazy"
        alt="logo"
        className="w-[100px] md:w-[170px] mx-auto my-4"
      />
      <Divider />
      <List>
        <div>
          {navItems.map((item, idx) => (
            <ListItem key={item.name} disablePadding>
              {item.dropdown ? (
                <div className="w-full text-left">
                  <ListItemButton onClick={handleDropdownToggle}>
                    <ListItemText primary={item.name} />
                    {dropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItemButton>
                  <AnimatePresence>
                    {dropdownOpen && (
                      <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        className="pl-6"
                      >
                        {item.dropdown.map((subItem) => (
                          <ListItem key={subItem.name} disablePadding>
                            <a href={subItem.src} className="pl-8">
                              <ListItemButton>
                                <ListItemText primary={subItem.name} />
                              </ListItemButton>
                            </a>
                          </ListItem>
                        ))}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              ) : (
                <a href={item.src} className="">
                  <ListItemButton sx={{ textAlign: "center" }}>
                    <ListItemText primary={item.name} />
                  </ListItemButton>
                </a>
              )}
            </ListItem>
          ))}
        </div>
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav" className="bg-yellow-500">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "block" },
              fontSize: "1.5rem",
            }}
          >
            <a href="/">
              <motion.div className="flex pl-20 items-center">
                <img
                  src={logo}
                  loading="lazy"
                  alt="logo"
                  className="w-[100px] md:w-[170px]"
                />
              </motion.div>
            </a>
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" }, px: 10 }}>
            <div className="flex items-center justify-center gap-x-14  w-full">
              {navItems.map((item) => (
                <div key={item.name} className="relative">
                  {item.dropdown ? (
                    <>
                      <motion.a
                        className="text-lg cursor-pointer flex items-center"
                        onClick={handleDropdownToggle}
                      >
                        {item.name}{" "}
                        {dropdownOpen ? (
                          <ExpandLessIcon className="ml-1" />
                        ) : (
                          <ExpandMoreIcon className="ml-1" />
                        )}
                      </motion.a>
                      <AnimatePresence>
                        {dropdownOpen && (
                          <motion.div
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            className="absolute bg-white shadow-lg rounded-lg p-3 mt-2 w-36"
                          >
                            {item.dropdown.map((subItem) => (
                              <a
                                key={subItem.name}
                                href={subItem.src}
                                className="block text-gray-700 hover:text-blue-500 py-1 border-b"
                              >
                                {subItem.name}
                              </a>
                            ))}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </>
                  ) : (
                    <motion.a
                      href={item.src}
                      className="text-lg cursor-pointer"
                    >
                      {item.name}
                    </motion.a>
                  )}
                </div>
              ))}
            </div>
          </Box>
        </Toolbar>
        {/* <Box sx={{ display: { xs: "block", sm: "none" }, textAlign: "center" }}>
          <a href="/">
            <motion.div className="flex justify-center items-center">
              <img
                src={logo}
                loading="lazy"
                alt="logo"
                className="w-[100px] md:w-[170px] border"
              />
            </motion.div>
          </a> */}
        {/* </Box> */}
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Toolbar />
    </Box>
  );
}

DrawerAppBar.propTypes = {
  window: PropTypes.func,
};

export default DrawerAppBar;
