import {
  Assignment as AssignmentIcon,
  Usb as UsbIcon,
  Security as SecurityIcon,
  VerifiedRounded,
} from "@mui/icons-material";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import VerifiedIcon from "@mui/icons-material/Verified";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import image1 from "../assets/slider-images/image4.jpg";
import image2 from "../assets/slider-images/image2.png";
import image3 from "../assets/slider-images/image3.jpg";
import image4 from "../assets/slider-images/image4.jpg";
import image5 from "../assets/slider-images/image5.jpg";
import image6 from "../assets/slider-images/customized.jpg";
export const navItems = [
  {
    name: "Why we?",
    src: "#why-we",
  },
  {
    name: "Features",
    src: "#features",
  },
  {
    name: "Pricing",
    src: "#pricing",
  },
  {
    name: "Contact Us",
    src: "#contact-us",
  },
  {
    name: "Login",
    src: "/login",
  },
];

export const cards = [
  {
    id: 1,
    title: "Digital Signature Certificate",
    description:
      "Digital signatures ensure the authenticity, integrity, and non-repudiation of digital messages or documents.",
    icon: <AssignmentIcon className="h-8 w-8 text-purple-600" />,
    bgColor: "bg-purple-200",
  },
  {
    id: 2,
    title: "E-Token",
    description:
      "eToken ensures secure USB authentication for online transactions and financial activities.",
    icon: <UsbIcon className="h-8 w-8 text-green-600" />,
    bgColor: "bg-green-200",
  },
  {
    id: 3,
    title: "Class 3 DSC",
    description:
      "Secure electronic document submissions with Digital Signatures mandated by the Information Technology Act, 2000.",
    icon: <SecurityIcon className="h-8 w-8 text-blue-600" />,
    bgColor: "bg-blue-200",
  },
  {
    id: 4,
    title: "Class 3 Individual DSC",
    description:
      "Class 3 Individual Digital Signature Certificates provide top-tier security for MCA21, ROC, EPFO, and more.",
    icon: <EmojiObjectsIcon className="h-8 w-8 text-blue-600" />, // Placeholder icon
    bgColor: "bg-blue-200", // Placeholder color
  },
  // {
  //   id: 5,
  //   title: "Class 3 Organisation DSC",
  //   description:
  //     "Class 3 Organization Digital Signatures ensure top-tier security for e-tendering, e-procurement, and e-bidding.",
  //   icon: <UsbIcon className="h-8 w-8 text-pink-600" />, // Placeholder icon
  //   bgColor: "bg-pink-200", // Placeholder color
  // },
  // {
  //   id: 6,
  //   title: "DGFT Certificate",
  //   description:
  //     "DGFT Digital Signature Certificates authenticate and assure integrity for importers and exporters.",
  //   icon: <SecurityIcon className="h-8 w-8 text-blue-600" />, // Placeholder icon
  //   bgColor: "bg-blue-200", // Placeholder color
  // },
  // {
  //   id: 7,
  //   title: "Consultancy Service for e-tender process",
  //   description:
  //     "We consult on e-Tenders for government and corporate websites like kppp.karnataka.gov.in, GeM, Tender Wizard, BHEL, BEML, and HAL.",
  //   icon: <AssignmentIcon className="h-8 w-8 text-purple-600" />, // Placeholder icon
  //   bgColor: "bg-purple-200", // Placeholder color
  // },
  {
    id: 8,
    title: "QR Codes for KSDA",
    description:
      "We provide Static and Dynamic QR Codes to KSDA Customers for Subsidy Purpose.",
    icon: <QrCode2Icon className="h-8 w-8 text-pink-600" />, // Placeholder icon
    bgColor: "bg-pink-200", // Placeholder color
  },
  {
    id: 9,
    title: "Application Development",
    description:
      "We provide custom applications on demand as per user requirements. This includes web development and desktop application services.",
    icon: <VerifiedRounded className="h-8 w-8 text-green-600" />, // Placeholder icon
    bgColor: "bg-green-200", // Placeholder color
  },
];
export const slides = [
  {
    title: "Your Data, Encrypted and Secure.",
    description:
      "Rest assured that your sensitive information is protected with advanced encryption techniques.",
    highlight: "Top-notch Data Security.",
    imageUrl: image1,
  },
  // {
  //   title: "Simplified QR Code Generation.",
  //   description:
  //     "Effortlessly generate QR codes in bulk, focusing on the essential details.",
  //   highlight: "Effortless QR Code Creation.",
  //   imageUrl: image2,
  // },
  {
    title: "Find Data in Seconds.",
    description:
      "Quickly access the data you need with our advanced filtering options.",
    highlight: "Instant Data Access.",
    imageUrl: image3,
  },
  {
    title: "Customized Reports at Your Fingertips.",
    description:
      "Easily download QR codes in various formats or request custom reports.",
    highlight: "Tailored Reporting Solutions.",
    imageUrl: image6,
  },
  {
    title: "Validate Directly with K-KISAN.",
    description: "Verify your data seamlessly using K-KISAN’s reliable API.",
    highlight: "Trusted Data Validation.",
    imageUrl: image5,
  },
];
export const features = [
  {
    title: "Login Page",
    description:
      "Feel confident knowing your crucial information is safely stored with top-notch encryption.",
    highlight: "Your Data, Encrypted and Secure.",
    bgColor: "bg-indigo",
    borderColor: "border-indigo",
  },
  {
    title: "QR Generation Page",
    description:
      "Modernize your workflow by generating QR codes in bulk effortlessly, focusing only on the details that matter.",
    highlight: "Simplified QR Code Generation.",
    bgColor: "bg-purple",
    borderColor: "border-purple",
  },
  {
    title: "Filters Page",
    description:
      "Say goodbye to delays—use our advanced filters to access the data you need instantly, hassle-free.",
    highlight: "Find Data in Seconds.",
    bgColor: "bg-blue-400",
    borderColor: "border-blue-400",
  },
  {
    title: "Generated Reports Page",
    description:
      "Download your QR codes in multiple formats or discuss custom reporting needs with our team for tailored solutions.",
    highlight: "Customized Reports at Your Fingertips.",
    bgColor: "bg-yellow-400",
    borderColor: "border-yellow-400",
  },
  {
    title: "Verification Page",
    description:
      "Ensure accuracy and transparency by verifying your data seamlessly with K-KISAN’s trusted API.",
    highlight: "Validate Directly with K-KISAN.",
    bgColor: "bg-green-500",
    borderColor: "border-green-500",
  },
];
