import React, { useEffect, useState } from "react";
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Topbar from "../components/Topbar";
import axios from "axios";
import UserGuard from "../hooks/UserGuard";
import QrCode2OutlinedIcon from "@mui/icons-material/QrCode2Outlined";
import Avatar from "@mui/material/Avatar";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import Loading from "../components/Loading";
import useAuth from "../hooks/useAuth";
import { BACKEND_PROD_URL } from "../config";
const VerifyDetails = () => {
  const [qrcode, setQrcode] = useState("");
  const [miqrcode, setMIQrcode] = useState("");
  const [primaryQrCode, setPrimaryQrCode] = useState("");
  const [primaryContent, setPrimaryContent] = useState([]);
  const [secondaryQrCode, setSecondaryQrCode] = useState("");
  const [secondaryContent, setSecondaryContent] = useState([]);
  const [productCode, setProductCode] = useState("");
  const [realProductCode, setRealProductCode] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [productContent, setProductContent] = useState([]);
  const [open, setOpen] = useState(false);
  const [qrType, setQrType] = useState("QR");
  const { uomidList, setuomidList } = useAuth();
  const [seedsClassList, setSeedsClassList] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  useEffect(() => {
    async function getUomID() {
      const token = localStorage.getItem("token");
      const configs = {
        headers: { Authorization: `Bearer ${token}` },
      };
      try {
        const res = await axios.get(
          `${BACKEND_PROD_URL}/api/admin/getUnitOfMeasurements`,
          configs
        );
        console.log(res);
        setuomidList(res.data);
      } catch (error) {
        console.log(error);
        setOpen(true);
        setSnackbarMessage("Error while getting UomID");
      }
    }

    async function getSeedsClass() {
      const token = localStorage.getItem("token");
      const configs = {
        headers: { Authorization: `Bearer ${token}` },
      };
      try {
        const res = await axios.get(
          `${BACKEND_PROD_URL}/api/admin/getSeedsClass`,
          configs
        );
        console.log(res);
        console.log(res.data);
        setSeedsClassList(res.data);
      } catch (error) {
        console.log(error);
        setOpen(true);
        setSnackbarMessage("Error while getting seed class");
      }
    }

    if (
      (seedsClassList !== undefined && seedsClassList.length === 0) ||
      (uomidList !== undefined && uomidList.length === 0)
    ) {
      if (seedsClassList !== undefined && seedsClassList.length === 0) {
        getSeedsClass();
      } else if (uomidList !== undefined && uomidList.length === 0) {
        getUomID();
      }
    }
    setPageLoading(false);
  }, []);

  //PrimaryQRcode
  useEffect(() => {
    const getPrimaryDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${BACKEND_PROD_URL}/api/qrcode/VerifyPrimaryQRDetailCtrl?primaryQrCode=${primaryQrCode}`
        );

        console.log(response.data);
        if (response.data.QRCode == null) {
          setLoading(false);
          setOpen(true);
          setSnackbarMessage("QRCode not found");
          setPrimaryQrCode("");
          setSecondaryQrCode("");
          setRealProductCode("");
          setPrimaryContent([]);
          setSecondaryContent([]);
        } else {
          setPrimaryContent(response.data);
          setPrimaryQrCode("");
          setSecondaryQrCode("");
          setRealProductCode(response.data.ProductCode);
          setSecondaryContent([]);

          setLoading(false);
          setOpen(true);
          setSnackbarMessage("Report Generated Successfully");
        }
      } catch (error) {
        setPrimaryQrCode("");
        setSecondaryQrCode("");
        setRealProductCode("");
        setPrimaryContent([]);
        setSecondaryContent([]);
        setLoading(false);
        setOpen(true);
        setSnackbarMessage("Something went wrong");
      }
    };
    const getPrimaryMIDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${BACKEND_PROD_URL}/api/qrcode/VerifyPrimaryMIQRDetailCtrl?primaryQrCode=${primaryQrCode}`
        );

        console.log(response.data);
        if (response.data.QRCode == null) {
          setLoading(false);
          setOpen(true);
          setSnackbarMessage("QRCode not found");
          setPrimaryQrCode("");
          setSecondaryQrCode("");
          setRealProductCode("");
          setPrimaryContent([]);
          setSecondaryContent([]);
        } else {
          setPrimaryContent(response.data);
          setPrimaryQrCode("");
          setSecondaryQrCode("");
          setRealProductCode(response.data.ProductCode);
          setSecondaryContent([]);

          setLoading(false);
          setOpen(true);
          setSnackbarMessage("Report Generated Successfully");
        }
      } catch (error) {
        setPrimaryQrCode("");
        setSecondaryQrCode("");
        setRealProductCode("");
        setPrimaryContent([]);
        setSecondaryContent([]);
        setLoading(false);
        setOpen(true);
        setSnackbarMessage("Something went wrong");
      }
    };
    if (primaryQrCode.length !== 0) {
      if (qrType === "QR") {
        getPrimaryDetails();
      }
      if (qrType === "MI") {
        getPrimaryMIDetails();
      }
    }
  }, [primaryQrCode]);

  //SecondaryQRode
  useEffect(() => {
    const getSecondaryDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${BACKEND_PROD_URL}/api/qrcode/VerifySecondaryQRDetailCtrl?secondaryQrCode=${secondaryQrCode}`
        );
        console.log(response.data);

        if (response.data.QRCode === null) {
          setLoading(false);
          setOpen(true);
          setSnackbarMessage("QRCode not found");
          setPrimaryQrCode("");
          setSecondaryQrCode("");
          setRealProductCode("");
          setPrimaryContent([]);
          setSecondaryContent([]);
        } else {
          setSecondaryContent(response.data);
          setPrimaryQrCode("");
          setSecondaryQrCode("");
          setRealProductCode(response.data.Product);

          setLoading(false);
          setOpen(true);
          setSnackbarMessage("Report Generated Successfully");
        }
      } catch (error) {
        setPrimaryQrCode("");
        setSecondaryQrCode("");
        setRealProductCode("");
        setPrimaryContent([]);
        setSecondaryContent([]);
        setLoading(false);
        setOpen(true);
        setSnackbarMessage("Something went wrong");
      }
    };
    if (secondaryQrCode.length !== 0) {
      getSecondaryDetails();
    }
  }, [secondaryQrCode]);

  //productCode
  useEffect(() => {
    const getProductDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${BACKEND_PROD_URL}/api/qrcode/VerifyProductQRDetailCtrl?realProductCode=${realProductCode}`
        );

        console.log(response.data);
        if (response.data.ProductCode === null) {
          setLoading(false);
          setOpen(true);
          setSnackbarMessage("Product Code not found");
          setPrimaryQrCode("");
          setSecondaryQrCode("");
          setRealProductCode("");
          setProductContent([]);
        } else {
          setProductContent(response.data);
          setPrimaryQrCode("");
          setSecondaryQrCode("");
          setRealProductCode("");
          setLoading(false);
          setOpen(true);
          setSnackbarMessage("Report Generated Successfully");
        }
      } catch (error) {
        console.log(error);
        setPrimaryQrCode("");
        setSecondaryQrCode("");
        setRealProductCode("");
        setProductContent([]);
        setLoading(false);
        setOpen(true);
        setSnackbarMessage("Something went wrong");
      }
    };
    console.log(realProductCode);
    if (realProductCode !== undefined && realProductCode.length !== 0) {
      getProductDetails();
    }
  }, [realProductCode]);

  const handleQRcode = (e, qrInputType) => {
    e.preventDefault();
    let localCode;
    if (qrInputType === "MI") {
      localCode = miqrcode;
    } else {
      localCode = qrcode;
    }
    if (localCode.length === 0) {
      setPrimaryQrCode("");
      setSecondaryQrCode("");
      setRealProductCode("");
      setPrimaryContent([]);
      setSecondaryContent([]);
      setOpen(true);
      setSnackbarMessage("Please Enter QR code");
    } else if (!/^\d+$/.test(localCode)) {
      setPrimaryQrCode("");
      setSecondaryQrCode("");
      setRealProductCode("");
      setPrimaryContent([]);
      setSecondaryContent([]);
      setOpen(true);
      setSnackbarMessage("Entered  QR Code is invalid");
    } else if (localCode.length > 12) {
      setPrimaryQrCode("");
      setSecondaryQrCode("");
      setRealProductCode("");
      setPrimaryContent([]);
      setSecondaryContent([]);
      setOpen(true);
      setSnackbarMessage("QR code cannot exceed 12 digits.");
    } else {
      const firstTwoLetters = localCode.substring(0, 2);
      if (firstTwoLetters === "01") {
        setQrType(qrInputType);
        setPrimaryQrCode(localCode.slice(2));
      } else if (firstTwoLetters === "00" && qrType !== "MI") {
        setSecondaryQrCode(localCode.slice(2));
      } else {
        setOpen(true);
        setSnackbarMessage('QR code must start with "01" or "00" | Invalid QR');
      }
    }
  };

  const handleProductCode = (e) => {
    e.preventDefault();
    if (productCode.length === 0) {
      setPrimaryQrCode("");
      setSecondaryQrCode("");
      setRealProductCode("");
      setProductContent([]);
      setOpen(true);
      setSnackbarMessage("Please Enter Product code");
    } else if (!/^\d+$/.test(productCode)) {
      setPrimaryQrCode("");
      setSecondaryQrCode("");
      setRealProductCode("");
      setProductContent([]);
      setOpen(true);
      setSnackbarMessage("Entered Product Code is invalid");
    } else if (productCode.length !== 14) {
      setPrimaryQrCode("");
      setSecondaryQrCode("");
      setRealProductCode("");
      setProductContent([]);
      setOpen(true);
      setSnackbarMessage("Product Code must be 14 digits");
    } else {
      setRealProductCode(productCode);
    }
  };

  const getUomNameById = (targetUomID) => {
    const foundUom = uomidList.find((uom) => uom.UomID === targetUomID);
    console.log(uomidList, targetUomID);
    return foundUom ? foundUom.UomName : "NA";
  };

  const getSeedsClassId = (targetSeedsClassID) => {
    const foundUom = seedsClassList.find(
      (seedsClass) => seedsClass.ClassId === targetSeedsClassID
    );
    console.log(seedsClassList, targetSeedsClassID);
    return foundUom ? foundUom.UomName : "NA";
  };

  return (
    <>
      {pageLoading === true ? (
        <div style={{ marginTop: "10px", textAlign: "center" }}>
          <b>We are working on your request. Please be patient.</b>
          <Loading />{" "}
        </div>
      ) : (
        <UserGuard>
          <Topbar />
          {open === true ? (
            <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              message={snackbarMessage}
              action={action}
            />
          ) : (
            <></>
          )}
          <center>
            <Avatar sx={{ m: 2, bgcolor: "secondary.main" }}>
              <QrCode2OutlinedIcon />
            </Avatar>
            <p style={{ margin: "10px", fontSize: "25px" }}>VERIFY DETAILS</p>
            <div
              style={{
                display: "flex",
                marginTop: "20px",
                flexDirection: "column",
              }}
            >
              <div>
                <TextField
                  required
                  style={{ margin: "5px", width: "250px" }}
                  label="QR Code verification"
                  variant="outlined"
                  value={qrcode}
                  onChange={(e) => setQrcode(e.target.value)}
                />
                <Button
                  style={{ margin: "10px", padding: "10px" }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={(e) => handleQRcode(e, "QR")}
                >
                  Submit
                </Button>
              </div>
              <div>
                <TextField
                  required
                  style={{ margin: "5px", width: "250px" }}
                  label="MI QR Code verification"
                  variant="outlined"
                  value={miqrcode}
                  onChange={(e) => setMIQrcode(e.target.value)}
                />
                <Button
                  style={{ margin: "10px", padding: "10px" }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={(e) => handleQRcode(e, "MI")}
                >
                  Submit
                </Button>
              </div>

              <div style={{ marginTop: "5px" }}>
                <TextField
                  required
                  style={{ margin: "5px", width: "250px" }}
                  label="Product Code Verification"
                  variant="outlined"
                  value={productCode}
                  onChange={(e) => setProductCode(e.target.value)}
                />
                <Button
                  style={{ margin: "10px", padding: "10px" }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleProductCode}
                >
                  Submit
                </Button>
              </div>
            </div>
          </center>

          <div>
            <div style={{ marginTop: "40px" }}>
              {Object.keys(productContent).length !== 0 ? (
                <div>
                  {loading === true ? (
                    <Loading />
                  ) : (
                    <TableContainer component={Paper}>
                      <h3 style={{ margin: "10px" }}>Product Code Details</h3>
                      <Table
                        sx={{ minWidth: 500 }}
                        stickyHeader
                        aria-label="sticky table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align={"center"}
                              style={{ minWidth: 50 }}
                            >
                              ProductCode
                            </TableCell>
                            <TableCell
                              align={"center"}
                              style={{ minWidth: 50 }}
                            >
                              ManufacturerName
                            </TableCell>
                            <TableCell
                              align={"center"}
                              style={{ minWidth: 50 }}
                            >
                              SupplierName
                            </TableCell>
                            <TableCell
                              align={"center"}
                              style={{ minWidth: 50 }}
                            >
                              CategoryName
                            </TableCell>
                            <TableCell
                              align={"center"}
                              style={{ minWidth: 50 }}
                            >
                              SubCategoryName
                            </TableCell>
                            <TableCell
                              align={"center"}
                              style={{ minWidth: 50 }}
                            >
                              ProductName
                            </TableCell>
                            <TableCell
                              align={"center"}
                              style={{ minWidth: 50 }}
                            >
                              BrandName
                            </TableCell>
                            <TableCell
                              align={"center"}
                              style={{ minWidth: 50 }}
                            >
                              UomID
                            </TableCell>
                            <TableCell
                              align={"center"}
                              style={{ minWidth: 50 }}
                            >
                              UomName
                            </TableCell>
                            <TableCell
                              align={"center"}
                              style={{ minWidth: 50 }}
                            >
                              Weight
                            </TableCell>
                            <TableCell
                              align={"center"}
                              style={{ minWidth: 50 }}
                            >
                              CM_L_BIS_Number
                            </TableCell>
                            <TableCell
                              align={"center"}
                              style={{ minWidth: 50 }}
                            >
                              GSM
                            </TableCell>
                            <TableCell
                              align={"center"}
                              style={{ minWidth: 50 }}
                            >
                              License Number
                            </TableCell>
                            <TableCell
                              align={"center"}
                              style={{ minWidth: 50 }}
                            >
                              Marketed By
                            </TableCell>
                            <TableCell
                              align={"center"}
                              style={{ minWidth: 50 }}
                            >
                              CIB Registration Certificate
                            </TableCell>
                            <TableCell
                              align={"center"}
                              style={{ minWidth: 50 }}
                            >
                              Seeds Class Name
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableCell align="center">
                            {String(productContent.ProductCode)}
                          </TableCell>
                          <TableCell align="center">
                            {String(productContent.ManufacturerName)}
                          </TableCell>
                          <TableCell align="center">
                            {String(productContent.SupplierName)}
                          </TableCell>
                          <TableCell align="center">
                            {String(productContent.CategoryName)}
                          </TableCell>
                          <TableCell align="center">
                            {String(productContent.SubCategoryName)}
                          </TableCell>
                          <TableCell align="center">
                            {String(productContent.ProductName)}
                          </TableCell>
                          <TableCell align="center">
                            {String(productContent.BrandName)}
                          </TableCell>
                          <TableCell align="center">
                            {String(productContent.UomID)}
                          </TableCell>
                          <TableCell align="center">
                            {String(getUomNameById(productContent.UomID))}
                          </TableCell>
                          <TableCell align="center">
                            {String(productContent.Weight)}
                          </TableCell>
                          <TableCell align={"center"}>
                            {String(productContent.CM_L_BIS_Number)}
                          </TableCell>
                          <TableCell align={"center"}>
                            {String(productContent.GSM)}
                          </TableCell>
                          <TableCell align={"center"}>
                            {String(productContent.LicenseNumber)}
                          </TableCell>
                          <TableCell align={"center"}>
                            {String(productContent.MarketedBy)}
                          </TableCell>
                          <TableCell align={"center"}>
                            {String(productContent.CIBRegistrationCertificate)}
                          </TableCell>
                          <TableCell align={"center"}>
                            {String(
                              getSeedsClassId(productContent.SeedClassId)
                            )}
                          </TableCell>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>

            <div style={{ marginTop: "40px" }}>
              {Object.keys(primaryContent).length !== 0 ? (
                <div>
                  {loading === true ? (
                    <Loading />
                  ) : (
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 500 }}
                        stickyHeader
                        aria-label="sticky table"
                      >
                        <TableHead>
                          <h3 style={{ margin: "10px" }}>QR Code Details</h3>
                          <TableRow>
                            <TableCell
                              align={"center"}
                              style={{ minWidth: 50 }}
                            >
                              QRCode
                            </TableCell>
                            <TableCell
                              align={"center"}
                              style={{ minWidth: 50 }}
                            >
                              ProductCode
                            </TableCell>
                            <TableCell
                              align={"center"}
                              style={{ minWidth: 50 }}
                            >
                              BatchNumber
                            </TableCell>
                            <TableCell
                              align={"center"}
                              style={{ minWidth: 50 }}
                            >
                              SerialNumber
                            </TableCell>
                            <TableCell
                              align={"center"}
                              style={{ minWidth: 50 }}
                            >
                              ManufactureDate
                            </TableCell>
                            <TableCell
                              align={"center"}
                              style={{ minWidth: 50 }}
                            >
                              ExpiryDate
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableCell align="center">
                            {String(primaryContent.QRCode)}
                          </TableCell>
                          <TableCell align="center">
                            {String(primaryContent.ProductCode)}
                          </TableCell>
                          <TableCell align="center">
                            {String(primaryContent.BatchNumber)}
                          </TableCell>
                          <TableCell align="center">
                            {String(primaryContent.SerialNumber)}
                          </TableCell>
                          <TableCell align="center">
                            {String(primaryContent.ManufactureDate)}
                          </TableCell>
                          <TableCell align="center">
                            {String(primaryContent.ExpiryDate)}
                          </TableCell>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>

            <div>
              {Object.keys(secondaryContent).length !== 0 ? (
                <div>
                  {loading === true ? (
                    <Loading />
                  ) : (
                    <div>
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: 500 }}
                          stickyHeader
                          aria-label="sticky table"
                        >
                          <TableHead>
                            <h3 style={{ margin: "10px" }}>QR Code Details</h3>
                            <TableRow>
                              <TableCell
                                align={"center"}
                                style={{ minWidth: 100 }}
                              >
                                QRCode
                              </TableCell>
                              <TableCell
                                align={"center"}
                                style={{ minWidth: 50 }}
                              >
                                SecondaryContainerCode
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableCell align="center">
                              {String(secondaryContent.QRCode)}
                            </TableCell>
                            <TableCell align="center">
                              {String(secondaryContent.SecondaryContainerCode)}
                            </TableCell>
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <h3
                        style={{
                          textAlign: "center",
                          margin: "10px",
                          marginTop: "20px",
                        }}
                      >
                        SecondaryLabelDetail
                      </h3>
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: 500 }}
                          stickyHeader
                          aria-label="sticky table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align={"center"}
                                style={{ minWidth: 50 }}
                              >
                                QRCode
                              </TableCell>
                              <TableCell
                                align={"center"}
                                style={{ minWidth: 50 }}
                              >
                                ProductCode
                              </TableCell>
                              <TableCell
                                align={"center"}
                                style={{ minWidth: 50 }}
                              >
                                BatchNumber
                              </TableCell>
                              <TableCell
                                align={"center"}
                                style={{ minWidth: 50 }}
                              >
                                SerialNumber
                              </TableCell>
                              <TableCell
                                align={"center"}
                                style={{ minWidth: 50 }}
                              >
                                ManufactureDate
                              </TableCell>
                              <TableCell
                                align={"center"}
                                style={{ minWidth: 50 }}
                              >
                                ExpiryDate
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {secondaryContent.SecondaryLabelDetail.map(
                              (value) => {
                                return (
                                  <TableRow key={value.QRCode}>
                                    <TableCell
                                      align="center"
                                      style={{ minWidth: 50 }}
                                    >
                                      {String(value.QRCode)}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{ minWidth: 50 }}
                                    >
                                      {String(value.ProductCode)}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{ minWidth: 50 }}
                                    >
                                      {String(value.BatchNumber)}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{ minWidth: 50 }}
                                    >
                                      {String(value.SerialNumber)}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{ minWidth: 50 }}
                                    >
                                      {String(value.ManufactureDate)}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{ minWidth: 50 }}
                                    >
                                      {String(value.ExpiryDate)}
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div></div>
        </UserGuard>
      )}
    </>
  );
};

export default VerifyDetails;
