import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import DrawerAppBar from "../components/Appbar";
import { slides } from "../components/Content";

const FeaturesPage = () => {
  return (
    <>
      <section id="features">
        <div className="relative">
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            autoplay
            pagination={{ clickable: true }}
            navigation={{
              nextEl: ".custom-next",
              prevEl: ".custom-prev",
            }}
            loop={true}
            modules={[Pagination, Navigation]}
            className="w-full"
          >
            {slides.map((slide, index) => (
              <SwiperSlide key={index}>
                <div className="relative">
                  <img
                    loading="lazy"
                    src={slide.imageUrl}
                    alt={`Slide ${index + 1}`}
                    className="w-full md:h-[100vh] h-[70vh] object-scale-down md:object-cover"
                  />

                  <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>

                  <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-white">
                    <p className="text-3xl lg:text-5xl font-bold mb-4 w-[300px] md:w-full">
                      {slide.title}
                    </p>
                    <p className="text-lg lg:text-md mb-4 px-8">
                      {slide.description}
                    </p>
                    <p className="text-lg lg:text-md mb-4 px-8">
                      {slide.highlight}
                    </p>
                    <div className="relative md:absolute -left-5 flex items-center justify-between  w-full px-4">
                      <button
                        className="custom-prev border bg-gray-200 p-3 rounded-full shadow-md hover:bg-gray-300 transition duration-200 flex items-center justify-center"
                        aria-label="Previous"
                      >
                        <ArrowBack className="text-gray-700 w-5 h-5" />
                      </button>

                      <button
                        className="custom-next bg-gray-200 border p-3 rounded-full shadow-md hover:bg-gray-300 transition duration-200 flex items-center justify-center"
                        aria-label="Next"
                      >
                        <ArrowForward className="text-gray-700 w-5 h-5" />
                      </button>
                    </div>
                    <div className="flex justify-center pt-10 items-center space-x-4">
                      <a href="#contact-us">
                        {" "}
                        <button
                          type="submit"
                          className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800 text-xl  rounded-lg px-5 py-3 text-center"
                        >
                          <div className="flex items-center justify-center gap-3">
                            Get a live demo
                          </div>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default FeaturesPage;
