import { BusinessCenter, IntegrationInstructions } from "@mui/icons-material";
import React from "react";

const BusinessCard = ({ heading, content, Icon, bgColor = "green-50" }) => {
  return (
    <div className="bg-white rounded-xl shadow-2xl p-6 text-center hover:scale-105 transition-transform duration-300">
      {/* Icon Section */}
      <div className="flex justify-center mb-4">
        <div className={`bg-${bgColor} p-4 rounded-full`}>{Icon}</div>
      </div>

      {/* Title */}
      <h3 className="text-lg font-semibold text-blue-600">{heading}</h3>

      {/* Description */}
      <p className="text-sm mt-2">{content}</p>
    </div>

    /*<div className="max-w-sm p-6 hover:scale-105 duration-150 rounded-lg shadow-lg bg-white h-64">
      <div className="h-[2px] w-full bg-lightGreen"></div>
      <div className="flex justify-center items-center w-12 h-12 rounded-full bg-green-100 mb-4">
        {Icon}
      </div>
      <h2 className="text-xl font-semibold text-gray-800">{heading}</h2>
      <p className="mt-2  font-light">{content}</p>
    </div> */
  );
};

export default BusinessCard;
