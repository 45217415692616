import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { BACKEND_PROD_URL } from "../config";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Loading from "../components/Loading";

import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import Topbar from "../components/Topbar";
import useAuth from "../hooks/useAuth";
import UserGuard from "../hooks/UserGuard";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0b3b66",
    },
    secondary: {
      main: "#0b3b66",
    },
  },
  typography: {
    fontWeightBold: 700,
  },
});

export default function UserChangePassword() {
  const [reqData, setReqData] = React.useState({});
  const [showPassword, setShowPassword] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [passwordtwo, setPasswordtwo] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [originalpassword, setOriginalPassword] = React.useState("");
  const { currentUser } = useAuth();
  const [showOriginalPassword, setShowOriginalPassword] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickShowOriginalPassword = () =>
    setShowOriginalPassword((showOriginalPassword) => !showOriginalPassword);

  const handlePassChange = (event) => {
    setPassword(event.target.value);
  };

  const handlePasstwoChange = (event) => {
    setPasswordtwo(event.target.value);
  };

  const handleOriginalPassChange = (event) => {
    setOriginalPassword(event.target.value);
  };

  React.useEffect(() => {
    if (Object.keys(reqData).length === 4) {
      const token = localStorage.getItem("token");
      async function updatePassword() {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        try {
          const res = await axios.post(
            `${BACKEND_PROD_URL}/api/user/changeUserPassword`,
            reqData,
            config
          );
          if (res.status === 200) {
            setOpen(true);
            setSnackbarMessage("Password changed successfully");
          } else {
            setOpen(true);
            setSnackbarMessage("Unknown error occured");
          }
        } catch (error) {
          console.log(error);
          if (error.response) {
            setOpen(true);
            setSnackbarMessage(error.response.data);
          } else {
            setOpen(true);
            setSnackbarMessage(error.message);
          }
        }
        setLoading(false);
      }
      if (Object.keys(reqData).length !== 0) {
        updatePassword();
        setLoading(true);
      }
      setReqData({});
      setLoading(false);
    }
  }, [reqData]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password.length < 6) {
      setOpen(true);
      setSnackbarMessage("Password length should be greater than 6");
    } else if (password !== passwordtwo) {
      setOpen(true);
      setSnackbarMessage("Password Mismatched");
    } else {
      const reqData = {
        email: currentUser.email,
        originalpassword: originalpassword,
        password: password,
        password2: passwordtwo,
      };
      console.log(reqData);
      setLoading(true);
      setReqData(reqData);
    }
  };

  return (
    <UserGuard>
      <ThemeProvider theme={theme}>
        <Topbar />
        <Container
          component="main"
          maxWidth="xs"
          style={{
            width: "100%",
            justifyContent: "center",
            alignContent: "center",
            marginTop: "10px",
          }}
        >
          {open === true ? (
            <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              message={snackbarMessage}
              action={action}
            />
          ) : (
            <></>
          )}
          <CssBaseline />
          <Box
            sx={{
              margin: "normal",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Change Password
            </Typography>
            {loading === true ? (
              <Loading />
            ) : (
              <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">
                        Current Password
                      </InputLabel>
                      <OutlinedInput
                        id="originalpassword"
                        type={showOriginalPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowOriginalPassword}
                              edge="end"
                            >
                              {showOriginalPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Current Password"
                        onInput={handleOriginalPassChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">
                        Password
                      </InputLabel>
                      <OutlinedInput
                        id="password"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                        onInput={handlePassChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">
                        Confirm Password
                      </InputLabel>
                      <OutlinedInput
                        id="password2"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                        onInput={handlePasstwoChange}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Change Password
                </Button>
              </Box>
            )}
          </Box>
        </Container>
      </ThemeProvider>
    </UserGuard>
  );
}
