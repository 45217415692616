import React, { useEffect } from "react";
import Topbar from "../components/Topbar";
import UserGuard from "../hooks/UserGuard";
import useAuth from "../hooks/useAuth";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import UserAuth from "./../hooks/UserAuth";
import { Container, Typography } from "@mui/material";
import home_bg from "../assets/home_bg.jpg";
import axios from "axios";
import NotificationDialog from "../components/NotificationDialog";
import { BACKEND_PROD_URL } from "../config";
const Home = () => {
  const [open, setOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const { currentUser } = useAuth();
  const [notifications, setNotifications] = React.useState();
  const [openDialog, setOpenDialog] = React.useState(false);
  useEffect(() => {
    async function fetchNotifications() {
      const notifications = await axios.get(
        `${BACKEND_PROD_URL}/api/user/getNotifications?userId=${currentUser._id}`
      );
      setNotifications(notifications.data.notifications);
      if (notifications) {
        setOpenDialog(true);
      }
    }
    fetchNotifications();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      {open === true ? (
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          message={snackbarMessage}
          action={action}
        />
      ) : (
        <></>
      )}

      {/* && currentUser.userrole !== "ADMIN" ? */}
      {notifications && currentUser.userrole !== "ADMIN" ? (
        <NotificationDialog
          open={openDialog}
          handleClose={handleCloseDialog}
          notifications={notifications}
          email={currentUser.email}
        />
      ) : (
        <></>
      )}
      <UserGuard>
        <Topbar />
        <Container>
          <Typography
            variant="h4"
            align="center"
            mt={5}
            gutterBottom
            style={{ fontFamily: "Arial", fontWeight: "bold" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              {" "}
              <h4 style={{ marginRight: "15px" }}>WELCOME </h4>{" "}
              <h3 style={{ color: "#09DF" }}> {currentUser.company} </h3>{" "}
            </div>
          </Typography>
          <Box mt={5}>
            <Grid
              container
              spacing={5}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12} md={6}>
                <img
                  loading="lazy"
                  src={home_bg}
                  alt="Home Layout"
                  style={{ width: "100%", height: "auto", borderRadius: "8px" }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  textAlign="center"
                  sx={{
                    backgroundColor: "#f5f5f5",
                    padding: "24px",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{ fontFamily: "Arial", fontWeight: "bold" }}
                  >
                    Maili's QR Code Solutions
                  </Typography>
                  <Typography variant="body1" style={{ fontFamily: "Arial" }}>
                    We are providing a solution that will cover the entire QR
                    code requirement of KSDA using our customized web
                    application. The application will be a Cloud-Based QR Code
                    solution that connects a central backend system via K-KISAN
                    APIs, which in turn handles the generation of respective
                    products OR Codes.
                  </Typography>
                  <br />
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{ fontFamily: "Arial", fontWeight: "bold" }}
                  >
                    Contact Us:
                  </Typography>
                  <Typography variant="body1" style={{ fontFamily: "Arial" }}>
                    ASHOK MAILI
                    <br />
                    MAILI ENTERPRISES
                    <br />
                    Building No.2045/A, 2nd Floor, 1st Main Road, RPC Layout,
                    Vijaynagar Club Road,
                    <br />
                    Vijaynagar, Bangalore - 560040
                    <br />
                    Mob: 9481777928 / 8970700867
                    <br />
                    e-mail: mailienterprise@gmail.com
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </UserGuard>
    </div>
  );
};

export default Home;
