import React from "react";
import DrawerAppBar from "../components/Appbar";
import HeroSection from "../components/HeroSection";
import WhyUsSection from "../components/WhyUsSection";
import Footer from "../components/Footer";
import ContactUs from "../components/ContactUs";
import FeaturesPage from "../pages/Features";
import ServicesSection from "../components/ServicesSection";
import PerfectSolution from "../components/PerfectSolution";
import ScrollToTopButton from "../components/ScrollToTop";

const LandingPage = () => {
  return (
    <>
      <DrawerAppBar />
      <HeroSection />
      <PerfectSolution />
      <FeaturesPage />

      <WhyUsSection />
      <ServicesSection />

      <section id="pricing">
        <div className="h-[300px] bg-primaryBlue my-10 w-full">
          <p className="font-bold text-center text-white text-4xl p-10">
            Pricing
          </p>
          <div className="flex text-center h-[100px] text-white text-xl items-center justify-center">
            <p>
              To know more about all are the services please
              <a className="underline ml-2" href="#contact-us">
                Contact Us
              </a>
            </p>
          </div>
        </div>
      </section>
      <ContactUs />
      <Footer />
      <ScrollToTopButton />
    </>
  );
};

export default LandingPage;
