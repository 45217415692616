import { motion } from "framer-motion";
import {
  IntegrationInstructions,
  FilterSharp,
  CompassCalibrationTwoTone,
  SecurityRounded,
  Print,
} from "@mui/icons-material";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import BusinessCard from "../pages/BusinessCard";

// Animation Variants
const fadeSlideUp = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const staggerContainer = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const PerfectSolution = () => {
  return (
    <section id="perfect-solution">
      <div className="border-[#989898] bg-blue-50 pb-16">
        {/* Section Heading */}
        <motion.div
          className="text-center p-5"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={fadeSlideUp}
        >
          <p className="font-bold md:text-4xl text-3xl pt-10">
            What Makes It the Perfect Solution for Agri-Businesses?
          </p>
          <span className="md:text-lg text-md">
            Generate QR codes with Kisaan QR and ensure transparency and
            traceability—simple and reliable!
          </span>
        </motion.div>

        {/* Features Section */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Grid Animation */}
          <motion.div
            className="grid grid-cols-1 md:grid-cols-3 gap-6"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={staggerContainer}
          >
            {/* K-KISAN Integration */}
            <motion.div variants={fadeSlideUp}>
              <BusinessCard
                Icon={
                  <IntegrationInstructions
                    fontSize="large"
                    className="text-blue-600"
                  />
                }
                heading={"K-KISAN Integration"}
                content={
                  "Seamlessly integrated with K-KISAN API to simplify product submissions for department tenders"
                }
                bgColor="bg-blue-100"
              />
            </motion.div>

            {/* Advanced Filters */}
            <motion.div variants={fadeSlideUp}>
              <BusinessCard
                Icon={
                  <FilterSharp fontSize="large" className="text-green-500" />
                }
                heading={"Advanced Filters"}
                content={
                  "Quickly find what you need with filters for data, batched, and QR codes—making data management easier than ever."
                }
                bgColor="bg-green-100"
              />
            </motion.div>

            {/* Printer Compatibility */}
            <motion.div variants={fadeSlideUp}>
              <BusinessCard
                heading={"Printer Compatibility"}
                Icon={<Print fontSize="large" className="text-red-500" />}
                content={
                  "Create and download detailed reports in PDF or Excel formats, perfect for tracking and analysis."
                }
                bgColor="bg-red-100"
              />
            </motion.div>
          </motion.div>

          {/* Second Row */}
          <motion.div
            className="flex items-center flex-col md:flex-row justify-between gap-12 pt-10 px-0"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={staggerContainer}
          >
            {/* Transparency & Data Security */}
            <motion.div variants={fadeSlideUp}>
              <BusinessCard
                bgColor="bg-purple-100"
                Icon={
                  <SecurityRounded
                    fontSize="large"
                    className="text-purple-600"
                  />
                }
                heading={"Transparency & Data Security"}
                content={
                  "Your data stays encrypted and secure, with a dedicated verification page for added transparency through K-KISAN."
                }
              />
            </motion.div>

            {/* Easy Report Generation */}
            <motion.div variants={fadeSlideUp}>
              <BusinessCard
                heading={"Easy Report Generation"}
                Icon={
                  <ThumbUpAltIcon fontSize="large" className="text-green-600" />
                }
                content={
                  "Quickly find what you need with filters for data, batched, and QR codes—making data management easier than ever."
                }
                bgColor="bg-orange-100"
              />
            </motion.div>
          </motion.div>
          <motion.div variants={fadeSlideUp} className="pt-8">
            <BusinessCard
              heading={"Empowering Sales with Smart QR Solutions."}
              Icon={
                <CompassCalibrationTwoTone
                  fontSize="large"
                  className="text-blue-600"
                />
              }
              content={
                "Effortlessly create QR codes tailored for private and public sales. With unique URLs for private codes, offer clients secure, detailed access to product information with just a scan."
              }
              bgColor="bg-blue-100"
            />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default PerfectSolution;
